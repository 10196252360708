export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "AccountActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Движение средств"])},
        "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить эту запись"])},
        "RemoveNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запись может появиться вновь при импорте банковской выписки"])},
        "ReBind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязать к другой квартире"])},
        "AddIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить поступление"])},
        "AddAsCash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наличные"])},
        "DoRebind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязать"])},
        "Fundraisings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сборы средств"])},
        "TotalBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итого нераспределённый остаток"])},
        "TotalDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полная сумма долга"])},
        "TotalDebtWithBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полная сумма долга с учётом остатка"])},
        "DefaultDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поступление наличными"])},
        "UseAsFine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Списать часть суммы на счёт ситэ без сбора средств (штраф, безвоздмездная помощь)"])},
        "FineAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "Use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Списать"])},
        "UseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
        "DefaultUseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Штраф за просроченный платёж"])},
        "RefreshFundraisings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перераспределить поступления по сборам"])},
        "Tenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жители"])},
        "Title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Подробности ", _interpolate(_named("building")), " ", _interpolate(_named("flat")), " ", _interpolate(_named("site"))])}
      },
      "en": {
        "AccountActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money movements"])},
        "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove record"])},
        "RemoveNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This money movement may appear again after importing bank account activity list."])},
        "ReBind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bind to another flat"])},
        "AddIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add income"])},
        "AddAsCash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash"])},
        "DoRebind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bind"])},
        "Fundraisings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundraising campaigns"])},
        "TotalBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final balance"])},
        "TotalDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total debt"])},
        "TotalDebtWithBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total debt including leftover balance"])},
        "DefaultDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash payment"])},
        "UseAsFine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deduct cash without fundraising campaign (fines or personal investment)"])},
        "FineAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "Use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deduct"])},
        "UseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "DefaultUseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine for late payment"])},
        "RefreshFundraisings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redistributes incomes to fundraising campaigns"])},
        "Tenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenants"])},
        "Title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Details for ", _interpolate(_named("building")), " ", _interpolate(_named("flat")), " ", _interpolate(_named("site"))])}
      },
      "tr": {
        "AccountActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para hareketleri"])},
        "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
        "RemoveNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir banka ekstresi içe aktarılırken kayıt yeniden görünebilir"])},
        "ReBind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başka bir daireye bağla"])},
        "AddIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varış ekleyin"])},
        "AddAsCash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakit"])},
        "DoRebind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bağla"])},
        "Fundraisings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para toplayıcılar"])},
        "TotalBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam dağıtılmamış bakiye"])},
        "TotalDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam borç"])},
        "TotalDebtWithBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam ödenmiş bakiye dahil borç"])},
        "DefaultDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakit ödeme"])},
        "UseAsFine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutarın bir kısmının tahsil edilmeden site hesabına yansıtılması (para cezası, bahşiş)"])},
        "FineAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
        "Use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahsil et"])},
        "UseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
        "DefaultUseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geç ödeme cezası"])},
        "RefreshFundraisings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para toplayarak geliri yeniden tahsis edin"])},
        "Tenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sakinleri"])},
        "Title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ayrıntılar ", _interpolate(_named("building")), " ", _interpolate(_named("flat")), " ", _interpolate(_named("site"))])}
      }
    }
  })
}
