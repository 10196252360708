import fetchApi from '../fetchApi';
import parseError from './parseError';

export class DebtCollection {
    constructor (source) {
        this.parse(source);
    }

    parse (source) {
        Object.assign(this, source);
        this.debtedFlats = source.debtedFlats.map(x => new DebtedFlat(x));
    }
}
export class DebtedFlat {
    constructor (source) {
        Object.assign(this, source);
        this.collected = source.followingAccountActivities.filter(a => a.amount > 0).reduce((acc, current) => acc + current.amount, 0);
    }
}
export async function createDebtCollection (site, threshold) {
    const result = await fetchApi('POST', '/api/debts/new/' + site, threshold);
    if (result.success) {
        return result.id;
    } else throw parseError(result);
}
export async function load (id) {
    const result = await fetchApi('GET', '/api/debts/' + id);
    if (result.success) {
        return new DebtCollection(result)
    } else throw parseError(result);
}
