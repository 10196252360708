<i18n src="localisation.json"/>
<i18n>
{
  "ru": {
    "Title": "Работа с задолженностью",
    "Close": "Завершить сбор"
  },
  "en": {
    "Title": "Debt collection",
    "Close": "Complete collection"
  },
  "tr": {
    "Title": "Borç yönetimi",
    "Close": "Koleksiyonu tamamlayın"
  }
}
</i18n>
<script setup>
import { ref } from 'vue';
import { load } from '../../Models/DebtCollection';
import DebtedFlat from './DebtedFlat.vue';
import { useI18n } from 'vue-i18n';
import fetchApi from '../../fetchApi';
import { router } from '../../main';

const props = defineProps({ siteName: String, id: String });
const { t } = useI18n();
document.title = t('Title') + t('MainTitle');

const data = ref(await load(props.id))

async function completeCollection () {
    await fetchApi('DELETE', '/api/debts/' + props.id);
    router.push('/details/' + encodeURIComponent(props.siteName));
}
</script>

<template>
  <Teleport to="#headerTeleportTarget">
    <router-link :to="`/details/${ encodeURIComponent(siteName) }`">{{ siteName }}</router-link>
    <span>
      {{ t('Title') }}
      <button :class="[buttons.small, buttons.red]" @click="completeCollection">{{ t('Close') }}</button>
    </span>
  </Teleport>
  <div>
    <DebtedFlat v-for="(item,i) in data.debtedFlats" :key="i" :item="item" :debt-collection-id="id"/>
  </div>

</template>
<style module="buttons" src="Components/Buttons.css"/>
<style module>
</style>
