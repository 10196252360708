<i18n src="localisation.json"/>
<i18n>
{
  "ru": {
    "ApprovedRelations": "Существующие привязки",
    "ClaimedRelations": "Привязки ожидающие одобрения",
    "NewClaim": "Запросить новую привязку",
    "NoRelationsAtAll": "Ваш пользователь не привязан ни к одной квартире. Запросите привязку используя форму ниже, после одобрения администрацией вы сможете видеть подробные данные по своей квартире и суммарные отчёты.",
    "ApproveTenant": "Утвердить",
    "IgnoreTenant": "Игнорировать",
    "RemoveTenant": "Удалить",
    "ClaimAlreadyExists": "Такая привязка уже существует, повторное добавление невозможно.",
    "Title": "Привязка пользователя к квартире"
  },
  "en": {
    "ApprovedRelations": "Existing bindings",
    "ClaimedRelations": "Bindings awaiting approval",
    "NewClaim": "Claim binding",
    "NoRelationsAtAll": "Your account is not bound to any flat. Claim binding using the form below, after management approval you will be able to access your flat details and site reports.",
    "ApproveTenant": "Approve",
    "IgnoreTenant": "Ignore",
    "RemoveTenant": "Remove",
    "ClaimAlreadyExists": "This binding already exists, it cannot be added again.",
    "Title": "Account to flat bindings"
  },
  "tr": {
    "ApprovedRelations": "Mevcut bağlamalar",
    "ClaimedRelations": "Bağlamalar onay bekliyor",
    "NewClaim": "Yeni bir bağlama talep edin",
    "NoRelationsAtAll": "Kullanıcınız herhangi bir daireye bağlı değil. Aşağıdaki formu kullanarak bir bağlama talep edin, yönetim tarafından onaylandıktan sonra daireniz için ayrıntılı verileri ve özet raporları görebileceksiniz.",
    "ApproveTenant": "Onayla",
    "IgnoreTenant": "Yoksay",
    "RemoveTenant": "Sil",
    "ClaimAlreadyExists": "Bu bağ zaten mevcut, tekrar eklenemez.",
    "Title": "Kullanıcıyı bir daireye bağlama"
  }
}
</i18n>
<template>
  <div :class="$style.wrapper">
    <teleport to="#headerTeleportTarget"><span>{{t('Title')}}</span></teleport>
    <template v-if="user.relations.length">
      <h4>{{ t('ApprovedRelations') }}</h4>
      <div v-for="(r,i) in user.relations" :key="i">
        {{ r.site }} {{ r.building }} {{ r.flat }} {{ t('TenantTypeGenitive.Title') }} {{ t('TenantTypeGenitive.' + r.relation) }}
        <button v-if="user.isManagerOf(r.site, r.building, r.flat)" :class="[buttons.small, buttons.red]" @click="removeTenant(r)">
          {{ t('RemoveTenant') }}
        </button>
      </div>
    </template>
    <template v-if="claims.length">
      <h4>{{ t('ClaimedRelations') }}</h4>
      <div v-for="(c,i) in claims" :key="i">
        <template v-if="c.person.id===user.id">
          {{ c.site }} {{ c.building }} {{ c.flat }} {{ t('TenantTypeGenitive.Title') }} {{ t('TenantTypeGenitive.' + c.relation) }}
        </template>
        <template v-else>
          {{ c.person.name }} &rarr;
          {{ c.site }} {{ c.building }} {{ c.flat }} {{ t('TenantTypeGenitive.Title') }} {{ t('TenantTypeGenitive.' + c.relation) }}
        </template>
        <template v-if="user.isManagerOf(c.site,c.building)">
          <button :class="[buttons.small, buttons.green]" @click="approveClaim(c)">{{ t('ApproveTenant') }}</button>
          <button :class="[buttons.small, buttons.orange]" @click="ignoreClaim(c)">{{ t('IgnoreTenant') }}</button>
          <button :class="[buttons.small, buttons.red]" @click="removeClaim(c)">{{ t('RemoveTenant') }}</button>
        </template>
      </div>
    </template>
    <template v-if="!claims.length && !user.relations.length && !user.superuser">
      {{ t('NoRelationsAtAll') }}
    </template>
    <h4>{{ t('NewClaim') }}</h4>
    <form :class="$style.hForm" @submit.stop.prevent="addClaim">
      <select v-model="selectedSite" v-if="sites.length>1">
        <option v-for="s in sites" :value="s.id" :key="s.id">{{ s.name }}</option>
      </select>
      <span v-else>{{ siteObj?.name }}</span>
      <select v-model="selectedBuilding" v-if="siteObj?.buildings.length>1">
        <option v-for="b in siteObj?.buildings" :value="b.id" :key="b.id">{{ b.name }}</option>
      </select>
      <select v-model="selectedFlat">
        <option v-for="f in buildingObj?.flats" :value="f.id" :key="f.id">{{ f.name }}</option>
      </select>
      <span>{{ t('TenantTypeGenitive.Title') }}</span>
      <select v-model="tenantType">
        <option value="owner">{{ t('TenantTypeGenitive.owner') }}</option>
        <option value="representative">{{ t('TenantTypeGenitive.representative') }}</option>
        <option value="renter">{{ t('TenantTypeGenitive.renter') }}</option>
        <option value="previousOwner">{{ t('TenantTypeGenitive.previousowner') }}</option>
        <option value="previousRenter">{{ t('TenantTypeGenitive.previousrenter') }}</option>
      </select>
      <button type="submit" :class="[buttons.green, buttons.small]">{{ t('Add') }}</button>
      <div v-if="claimError">{{t('ClaimAlreadyExists')}}</div>
    </form>
  </div>
</template>
<style module="buttons" src="Components/Buttons.css"/>
<style module>
.hForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.hForm > * {
  margin-right: 1em;
}
</style>
<script setup>
import { inject, ref, watch } from 'vue';
import { load } from 'Models/Site';
import { useI18n } from 'vue-i18n';
import Person from 'Models/Person';
import { ValidationError } from 'Models/parseError';

const { t } = useI18n();

const { claims, reloadClaims } = inject('claims');
const user = inject('user');

const sites = ref([]);
const siteObj = ref(null);
const buildingObj = ref(null);
const selectedSite = ref(null);
const selectedBuilding = ref(null);
const selectedFlat = ref(null);
const tenantType = ref('owner');
const claimError = ref(false);
document.title = t('Title') + t('MainTitle');

sites.value = await load();
siteObj.value = sites.value[0];
buildingObj.value = siteObj.value?.buildings[0];
selectedSite.value = siteObj.value?.id;
selectedBuilding.value = buildingObj.value?.id;
selectedFlat.value = buildingObj.value?.flats[0].id;

watch(selectedBuilding, (val) => {
    const b = siteObj.value.buildings.find(x => x.id === val);
    buildingObj.value = b;
    selectedFlat.value = b.flats[0].id;
});
watch(selectedSite, (val) => {
    const s = sites.value.find(x => x.id === val);
    siteObj.value = s;
    selectedBuilding.value = s.buildings[0].id;
});

async function addClaim () {
    try {
        await Person.prototype.bindToFlat.call(user.value, siteObj.value.name, buildingObj.value.name, buildingObj.value.flats.find(x => x.id === selectedFlat.value).name, tenantType.value);
        claimError.value = false;
        await reloadClaims();
    } catch (e) {
        if (e instanceof ValidationError) { claimError.value = true; }
    }
}

async function removeTenant (rel) {
    await Person.prototype.unbindFromFlat.call(user.value, rel.site, rel.building, rel.flat);
}

async function approveClaim (claim) {
    await claim.person.bindToFlat(claim.site, claim.building, claim.flat, claim.relation);
    await claim.delete();
    await reloadClaims();
}

async function ignoreClaim (claim) {
    await claim.ignore();
    await reloadClaims();
}

async function removeClaim (claim) {
    await claim.delete();
    await reloadClaims();
}

</script>
