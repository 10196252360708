export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "RemoveTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить жителя"])},
        "EditTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать жителя"])},
        "UserFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь с указаным адресом почты или телефоном найден. Можно использовать его, нажав соответствующую кнопку ниже."])},
        "UsePerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать существующего"])}
      },
      "en": {
        "RemoveTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove tenant"])},
        "EditTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit tenant"])},
        "UserFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User with the specified email/phone has been found. You can use it by pressing corresponding button below."])},
        "UsePerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use existing"])}
      },
      "tr": {
        "RemoveTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sakini silin"])},
        "EditTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sakini düzenleyin"])},
        "UserFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirtilen mail adresine veya telefon numarasına sahip bir kullanıcı bulundu. Aşağıdaki ilgili butona tıklayarak kullanabilirsiniz."])},
        "UsePerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut kullanıcıyı kullanma"])}
      }
    }
  })
}
