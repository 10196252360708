import { decodeJwt } from 'jose';
import dayjs from 'dayjs';
import Person from './Models/BasePerson';

class AuthHelper {
    constructor () {
        const sToken = sessionStorage.getItem('token');
        this.__profilePromise = new Promise((resolve, reject) => {
            this.__profilePromiseResolve = resolve;
            this.__profilePromiseReject = reject;
        })
        this.onProfileChange = null;
        if (sToken) {
            this.persistentAuth = null;
            if (this.consumeProfile({ token: sToken })) {
                this.refetchProfile();
                return;
            }
        }
        this.persistentAuth = (async () => {
            const rd = await fetch('/api/person/signin', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: 'null' });
            if (rd.status === 200) {
                const body = await rd.json();
                if (body.success) { this.consumeProfile(body); }
            }
        })();
        this.token = null;
        this.profile = null;
        setInterval(async () => {
            if (!this.token) return;
            if (!this.__tokenExpiry) return;
            if (this.__tokenExpiry.add(-5, 'm').isBefore(dayjs())) {
                this.refetchProfile();
            }
        }, 10000);
    }

    async refetchProfile () {
        const rd = await fetch('/api/person/signin', { method: 'POST', headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.token }, body: 'null' });
        if (rd.status === 200) {
            const body = await rd.json();
            if (body.success) { this.consumeProfile(body); }
        }
    }

    get profile () {
        return this.__profile;
    }

    get profileLoaded () {
        return this.__profilePromise;
    }

    set profile (val) {
        if (val) { val = new Person(val); }
        this.__profile = val;
        if (this.__profilePromiseResolve) {
            this.__profilePromiseResolve(val);
            this.__profilePromiseResolve = null;
        } else { this.__profilePromise = Promise.resolve(val); }
        if (this.onProfileChange) this.onProfileChange(val);
    }

    get token () {
        return this.__token;
    }

    set token (val) {
        this.__token = val;
        if (val) sessionStorage.setItem('token', val); else sessionStorage.removeItem('token');
    }

    async isAuthenticated () {
        if (this.persistentAuth) await this.persistentAuth;
        return !!this.token;
    }

    consumeProfile (body) {
        try {
            const profile = decodeJwt(body.token);
            if (dayjs(profile.exp).isAfter(dayjs())) {
                this.token = body.token;
                this.__tokenExpiry = dayjs(profile.exp);
                this.profile = profile.data;
                return true;
            }
        } catch (e) {
            console.log('Cannot consume profile', e);
        }
        this.token = null;
        this.profile = null;
        return false;
    }

    async tryLogin (identity, password) {
        const response = await fetch('/api/person/signin', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ identity, password }) });
        if (response.status === 200) {
            const body = await response.json();
            if (body.success) {
                return this.consumeProfile(body);
            }
        }
        return false;
    }

    clear () {
        sessionStorage.removeItem('token');
    }
}

export default new AuthHelper()
