<template>
  <div>
    <div @click="toggleDropDown" ref="handle" v-bind="$attrs">
        <slot></slot>
    </div>
    <template v-if="droppedDown">
        <Teleport to="#overlayTeleportTarget">
            <DropDownVisual :container="me" ref="drop">
                <slot name="drop"/>
            </DropDownVisual>
        </Teleport>
    </template>
  </div>
</template>
<script>
import DropDownVisual from './DropDownVisual';

export default {
    components: { DropDownVisual },
    inheritAttrs: false,
    data () {
        return {
            droppedDown: false
        };
    },
    computed: {
        me () {
            return this.$refs.handle;
        }
    },
    methods: {
        maybeHideDropDown (ev) {
            let el = ev.target;
            const ncParents = [this.$refs.handle];
            if (this.$refs.drop) { ncParents.push(this.$refs.drop.$el); }
            let hasAElement = false;
            while (el !== null) {
                hasAElement |= (el.tagName === 'A');
                for (let i = 0; i < ncParents.length; i++) {
                    if (ncParents[i] === el) {
                        if (hasAElement) this.droppedDown = false;
                        return;
                    }
                }
                el = el.parentElement;
            }
            this.droppedDown = false;
            ev.preventDefault();
            ev.stopPropagation();
            return false;
        },
        toggleDropDown () {
            this.droppedDown = !this.droppedDown;
            if (this.droppedDown) {
                document.body.addEventListener('click', (ev) => this.maybeHideDropDown(ev));
            } else {
                document.body.removeEventListener('click', (ev) => this.maybeHideDropDown(ev));
            }
        }
    }
};
</script>
