export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступные объекты"])},
        "SiteManager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Управляющий жилым комплексом ", _interpolate(_named("site"))])},
        "BuildingManager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Управляющий зданием ", _interpolate(_named("building")), " жилого комплекса ", _interpolate(_named("site"))])},
        "FlatTenant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("relation")), " квартиры ", _interpolate(_named("site")), " » ", _interpolate(_named("building")), " » ", _interpolate(_named("flat"))])}
      },
      "en": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available objects"])},
        "SiteManager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("site")), " site manager"])},
        "BuildingManager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("site")), " ", _interpolate(_named("building")), " manager"])},
        "FlatTenant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("relation")), " of ", _interpolate(_named("site")), " » ", _interpolate(_named("building")), " » ", _interpolate(_named("flat"))])}
      },
      "tr": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanılabilir tesisler"])},
        "SiteManager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("site")), " yönetici"])},
        "BuildingManager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("site")), " ", _interpolate(_named("building")), " apartman yönetici"])},
        "FlatTenant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("site")), " » ", _interpolate(_named("building")), " » ", _interpolate(_named("flat")), " ", _interpolate(_named("relation"))])}
      }
    }
  })
}
