export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импорт банковской выписки со счёта"])},
        "SelectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите файл"])},
        "UploadForSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для какого объекта выполнятся импорт"])},
        "FileFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат выписки"])},
        "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить"])},
        "ImportedRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результаты импорта"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние"])}
      },
      "en": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import bank account activity records"])},
        "SelectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select file"])},
        "UploadForSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target account/site"])},
        "FileFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity records format"])},
        "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
        "ImportedRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import results"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "tr": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaptan banka ekstresini içe aktarma"])},
        "SelectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosya seçin"])},
        "UploadForSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedeflenen site"])},
        "FileFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstre formatı"])},
        "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükle"])},
        "ImportedRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuçları içe aktar"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])}
      }
    }
  })
}
