<i18n>
{
  "ru": {
    "FundraisingsFulfillment": "Сборы средств",
    "Expenses": "Расходы",
    "AddCashSpending": "Добавить расход наличными",
    "TotalIncome": "Всего собрано за месяц",
    "TotalSpendings": "Всего расходы за месяц",
    "Title": "Отчёт за {period} для {site}",
    "Paid": "Оплачено",
    "Unpaid": "Не оплачено",
    "Exempted": "Освобождён от оплаты"
  },
  "en": {
    "FundraisingsFulfillment": "Fundraising campaigns fulfillment",
    "Expenses": "Expenses",
    "AddCashSpending": "Add cash expense",
    "TotalIncome": "Month total income",
    "TotalSpendings": "Month total expenses",
    "Title": "{period} report for {site}",
    "Paid": "Paid",
    "Unpaid": "Not paid",
    "Exempted": "Exempted"
  },
  "tr": {
    "FundraisingsFulfillment": "Aidatların ve para toplanması takip edilmesi",
    "Expenses": "Giderler",
    "AddCashSpending": "Nakit harcama ekleyin",
    "TotalIncome": "Ay için toplanan toplam tutar",
    "TotalSpendings": "Ay için toplam giderler",
    "Title": "{site} {period} raporu",
    "Paid": "Ödenmiş",
    "Unpaid": "Ödenmemiş",
    "Exempted": "Ödemeden muaftır"
  }
}
</i18n>
<i18n src="localisation.json"/>
<script setup>
import dayjs from 'dayjs';
import { computed, inject, ref, watch } from 'vue';
import fetchApi from 'fetchApi';
import { useI18n } from 'vue-i18n';
import { money } from 'Components/displays';
import SpendingRow from './SpendingRow';
import { useRoute } from 'vue-router';

const route = useRoute();
const { t } = useI18n();
const props = defineProps({ site: String, month: String, year: String });
const user = inject('user');
const date = ref(dayjs(new Date(Number(props.year), Number(props.month) - 1, 1)));
const isCurrentMonth = computed(() => Number(props.year) < dayjs().year() || (Number(props.year) === dayjs().year() && Number(props.month) <= dayjs().month()));
const nextMonth = computed(() => date.value.add(1, 'month').format('YYYY/MM'));
const prevMonth = computed(() => date.value.add(-1, 'month').format('YYYY/MM'));
document.title = t('Title', { site: props.site, period: date.value.format('MMMM YYYY') }) + t('MainTitle');
const report = ref(await fetchApi('GET', `/api/account/report/${encodeURIComponent(props.site)}/${props.year}/${props.month}`))
const sites = await fetchApi('GET', '/api/structure/site/' + encodeURIComponent(props.site));
const siteStructure = ref(null);
const totalSpending = computed(() => report.value?.spendings.reduce((a, s) => Number(a) + Number(s.amount), 0));
const editingRow = ref(null);
const spendingDate = ref(dayjs().format('YYYY-MM-DD'));
const spendingAmount = ref(0);
const spendingDescription = ref('');

async function changeMonth () {
    date.value = dayjs(new Date(Number(route.params.year), Number(route.params.month) - 1, 1));
    report.value = await fetchApi('GET', `/api/account/report/${encodeURIComponent(props.site)}/${props.year}/${props.month}`);
}

watch(() => route.params.month, changeMonth);
watch(() => route.params.year, changeMonth);

siteStructure.value = sites.site;

function paymentType (fundraising, site, building, flat) {
    if (fundraising.flats.some(x => x.site === site && x.building === building && x.flat === flat)) return 'paid';
    if (fundraising.exemptedFlats.some(x => x.site === site && x.building === building && x.flat === flat)) return 'exempted';
    return 'unpaid';
}

function fundraisingsForBuilding (building, fundraisings) {
    return fundraisings.filter(f => !f.building || f.building === building.name);
}

async function addSpending () {
    await fetchApi('POST', '/api/account/add-spending', { site: props.site, amount: spendingAmount.value, description: spendingDescription.value, date: spendingDate.value })
    report.value = await fetchApi('GET', `/api/account/report/${encodeURIComponent(props.site)}/${props.year}/${props.month}`);
}
</script>

<template>
  <div>
    <Teleport to="#headerTeleportTarget">
      <router-link :to="`/details/${props.site}`">{{ props.site }}</router-link>
      <span>
        <router-link :class="bits.nonPrintable" :to="`/report/${encodeURIComponent(props.site)}/${prevMonth}`">&larr;</router-link>
        {{ date.format('YYYY') }} - {{ date.format('MMMM') }}
        <router-link v-if="isCurrentMonth" :class="bits.nonPrintable" :to="`/report/${encodeURIComponent(props.site)}/${nextMonth}`">&rarr;</router-link>
      </span>
    </Teleport>
    <h3>{{ t('Expenses') }}</h3>
    <div :class="$style.table">
      <div :class="$style.tableHeadRow">
        <span>{{ t('Date')}} </span>
        <span>{{ t('Description') }}</span>
        <span>{{ t('Amount') }}</span>
        <span></span>
      </div>
      <SpendingRow v-for="aar in report.spendings" :key="aar.reference" :activity-record="aar" :site="site" @end-edit="editingRow=null" @start-edit="editingRow=aar" :is-editing="editingRow===aar" />
      <div :class="$style.tableRow">
        <span></span>
        <span>{{ t('TotalSpendings') }}</span>
        <span>{{ money(totalSpending) }}</span>
        <span></span>
      </div>
      <div :class="$style.tableRow">
        <span></span>
        <span>{{ t('TotalIncome') }}</span>
        <span>{{ money(report.income) }}</span>
        <span></span>
      </div>
      <div v-if="user?.isManagerOf(site)" :class="[$style.tableHeadRow, bits.nonPrintable]">
        <span></span>
        <span>{{ t('AddCashSpending') }}</span>
        <span></span>
        <span></span>
        </div>
      <form v-if="user?.isManagerOf(site)" :class="[$style.tableRow, bits.nonPrintable]" @submit.stop.prevent="addSpending">
        <span><input :class="$style.inputNoBorder" v-model="spendingDate" type="date"/></span>
        <span><input :class="$style.inputNoBorder" v-model="spendingDescription" type="text"/></span>
        <span><input :class="$style.inputNoBorder" v-model="spendingAmount" type="number"/></span>
        <span><button type="submit" :class="[buttons.blue, buttons.xsmall]">{{ t('Add') }}</button></span>
      </form>
    </div>
    <h3>{{ t('FundraisingsFulfillment') }}</h3>
    <div v-if="siteStructure" v-for="(building,i) in siteStructure.buildings" :key="building.id"
         :class="$style['building'+(i%4)]"
         :style="{'grid-template-columns':'[s] auto repeat('+building.flats.length+', 1fr) [e]'}">
      <div :class="$style.buildingName">{{ building.name }}</div>
      <div></div>
      <div v-for="flat in building.flats" :key="flat.id" :class="$style.flatName">{{ flat.name }}</div>
      <div v-for="fundraising in fundraisingsForBuilding(building, report.fundraisings)" :key="fundraising.id"
           :class="$style.fundraisingRow">
        <div :class="$style.fundraisingName">{{ fundraising.name }}</div>
        <div v-for="flat in building.flats" :key="flat.id"
             :class="[$style.flat, $style[paymentType(fundraising, site, building.name, flat.name)]]"></div>
      </div>
    </div>
    <div :class="$style.legend">
      <div :class="[$style.sample,$style.paid]"></div> {{t('Paid')}}
      <div :class="[$style.sample,$style.unpaid]"></div> {{t('Unpaid')}}
      <div :class="[$style.sample,$style.exempted]"></div> {{t('Exempted')}}
    </div>
  </div>
</template>
<style module="buttons" src="Components/Buttons.css"/>
<style module="bits" src="Components/Bits.css"/>
<style module="fa" src="Components/fontawesome/index.css"/>
<style module>
.building {
  display: grid;
  padding: 1em 0;
}
@media print{
  .building{
    padding: .5em 0 0 0;
  }
}
@media only screen and (max-width: 600px){
  .building{
    overflow-y: auto;
    padding: .5em 0 0 0;
  }
}

.building0 {
  composes: building;
  background-color: var(--color-blue-highlight);
}

.building1 {
  composes: building;
  background-color: var(--color-green-highlight);
}

.building2 {
  composes: building;
  background-color: var(--color-orange-highlight);
}

.building3 {
  composes: building;
  background-color: var(--color-red-highlight);
}

.cell {
}

.building .cell {
  padding: .1em .25em;
}

.buildingName {
  composes: cell;
  grid-column: s/e;
  font-size: 120%;
  font-weight: bold;
  text-align: center;
}

.flatName {
  composes: cell;
  text-align: center;
  font-weight: bold;
}

.fundraisingRow {
  display: contents;
}

.fundraisingName {
  composes: cell;
  font-weight: normal;
}

.flat {
  composes: cell;
  text-align: center;
}

.legend{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: .5em 0 0 0;
}

.legend>*{
  margin: 0 1em;
}

.sample{
  width: 2em;
  height: 1.5em;
}

.paid {

}

.paid:before {
  content: '✔';
}

.unpaid {
  background-color: var(--color-red);
}

.exempted{
  background-color: var(--color-gray);
}

.table {
  composes: stripedTable from 'Components/Table.css';
  grid-template-columns: auto auto auto 1fr;
}

.tableRow {
  composes: stripedTableRow from 'Components/Table.css';
}

.tableHeadRow {
  composes: stripedTableHeadRow from 'Components/Table.css';
}

.inputNoBorder{
  border: none;
  outline: none;
  display: block;
  width: 100%;
  margin: .25em 0;
}
</style>
