import BasePerson from './BasePerson';
import fetchApi from '../fetchApi';
import parseError from './parseError';
export default class Person extends BasePerson {
    async load () {
        if (!this.id) throw new Error('Cannot load Person without id');
        const result = await fetchApi('GET', '/api/person/' + this.id);
        if (result.success) {
            delete result.success;
            delete result.error;
            Object.assign(this, result);
        } else throw new Error('Error loading Person ' + JSON.stringify(result.error));
    }

    async save () {
        const result = await fetchApi('PUT', this.id ? ('/api/person/' + this.id) : '/api/person', this);
        if (result.success) {
            delete result.success;
            delete result.error;
            Object.assign(this, result);
            return result;
        } else throw parseError(result);
    }

    async bindToFlat (site, building, flat, type) {
        const result = await fetchApi('POST', '/api/person/' + this.id + '/bind/' + encodeURIComponent(site) + '/' + encodeURIComponent(building) + '/' + encodeURIComponent(flat), type);
        if (result.success) {
            await Person.prototype.load.call(this);
            return result;
        }
        throw parseError(result);
    }

    async unbindFromFlat (site, building, flat) {
        const result = await fetchApi('DELETE', '/api/person/' + this.id + '/bind/' + encodeURIComponent(site) + '/' + encodeURIComponent(building) + '/' + encodeURIComponent(flat));
        if (result.success) {
            await Person.prototype.load.call(this);
            return result;
        }
        throw parseError(result);
    }
}
