<template>
    <div :class="checkbox.checkControl">
        <input type="hidden" :name="name" value="falseValue">
        <input :id="id" type="checkbox" v-bind="attrs" :checked="isChecked" @change="onChange">
        <label :for="id">
            <slot/>
        </label>
    </div>
</template>
<style src="./Check.css" module="checkbox"/>
<script>
import IdGen from './idGenerator';

export default {
    props: {
        id: {
            type: String,
            default () {
                return IdGen();
            }
        },
        modelValue: [Boolean, Array, String],
        name: [String, Number],
        required: Boolean,
        disabled: Boolean,
        indeterminate: Boolean,
        trueValue: {
            default: true
        },
        falseValue: {
            default: false
        },
        value: [String, Number, Boolean, Object]
    },
    computed: {
        attrs () {
            const attrs = {
                id: this.id,
                name: this.name,
                disabled: this.disabled,
                required: this.required,
                'true-value': this.trueValue,
                'false-value': this.falseValue
            };

            return attrs;
        },
        isChecked () {
            return !(this.modelValue === 'N') && this.modelValue;
        }
    },
    methods: {
        onChange ($event) {
            this.$emit('update:modelValue', $event.target.checked);
        }
    }
};
</script>
