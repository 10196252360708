<script setup>
import { money, transactionDate, transactionName } from 'Components/displays';
import { inject, ref } from 'vue';
import fetchApi from 'fetchApi';
const props = defineProps({ activityRecord: Object, site: String, isEditing: Boolean });
const emit = defineEmits(['start-edit', 'end-edit']);
const user = inject('user');
const newDescription = ref(props.activityRecord.description);

async function saveDescription () {
    await fetchApi('PATCH', `/api/account/movement/${props.activityRecord.id}`, newDescription.value)
    props.activityRecord.description = newDescription.value;
    emit('end-edit');
}
</script>

<template>
  <div :class="$style.tableRow">
    <span>{{ transactionDate(activityRecord.timestamp) }}</span>
    <form v-if="isEditing" :class="$style.editCell" @submit.stop.prevent="saveDescription">
      <input type="text" v-model="newDescription"/>
      <button type="submit" :class="fa.check"></button>
      <button type="reset" :class="fa.remove" @click="newDescription=activityRecord.description;$emit('end-edit')"></button>
    </form>
    <span v-else>{{ activityRecord.id?transactionName(activityRecord.description):activityRecord.description }} <button v-if="user?.isManagerOf(site) && activityRecord.id" :class="fa.pencil" @click="$emit('start-edit')"></button></span>
    <span>{{ money(activityRecord.amount) }}</span>
    <span></span>
  </div>
</template>
<style module="fa" src="Components/fontawesome/index.css"/>
<style module>
.tableRow {
  composes: stripedTableRow from 'Components/Table.css';
}
.editCell{
  display: grid;
  grid-template-columns: 1fr auto auto;
}
</style>
