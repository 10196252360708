export default class {
    constructor (src) {
        Object.assign(this, {
            id: null,
            name: null,
            phone: null,
            email: null,
            superuser: false,
            metadata: {},
            relations: []
        }, src);
    }

    get isManager () {
        return this.superuser || (this.relations && this.relations.some(x => x.relation === 'Manager'));
    }

    isManagerOf (site, building, flat) {
        if (this.superuser) return true;
        return this.relations && this.relations.some(x =>
            (x.relation === 'Manager' && (
                (x.site === site && !x.building) ||
                (x.site === site && x.building === building && building)
            )) || (flat && x.relation === 'owner' && x.site === site && x.building === building && x.flat === flat)
        );
    }
}
