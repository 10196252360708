<i18n>
{
  "ru": {
    "CreateRule": "Создать правило для повторяющихся платежей",
    "TSQuery": "TSQuery запрос",
    "SelectFlat": "Выберите одну или несколько квартир, к которой нужно привязать платёж",
    "RuleForMoreThanOneFlatImpossible": "Платёж будет распределён поровну между выбранными квартирами. Правило повторяющихся платежей не может быть создано для нескольких квартир.",
    "Resolve": "Привязать платёж",
    "ImportedBefore": "Импортировано ранее для {link}",
    "SuccessFor": "Импортировано для {link}",
    "SuccessExpense": "Импортировано как расход",
    "ProblemMoreThanOneMatch": "Более одного подходящего счёта",
    "ProblemZeroMatch": "Не найдено подходящего счёта",
    "JustRemoveExisting": "{resolve} или {remove} (восстановятся при следующем импорте)",
    "Remove": "Удалить старые записи",
    "Changed": "Запись изменена, повторите импорт чтобы изменить ещё"
  },
  "en": {
    "CreateRule": "Create rule for recurring payments",
    "TSQuery": "TSQuery",
    "SelectFlat": "Select one or more flat to bind payment to",
    "RuleForMoreThanOneFlatImpossible": "Payment will be split evenly between selected flats. Recurring payment rule cannot be created for multi-flat payments.",
    "Resolve": "Bind payment",
    "ImportedBefore": "Imported earlier for {link}",
    "SuccessFor": "Imported for {link}",
    "SuccessExpense": "Imported as spending",
    "ProblemMoreThanOneMatch": "More than one matching account",
    "ProblemZeroMatch": "No matching account found",
    "JustRemoveExisting": "{resolve} or {remove} (will appear again on next import)",
    "Remove": "Remove old records",
    "Changed": "Record changed. Import once again for more changes."
  },
  "tr": {
    "CreateRule": "Yinelenen ödemeler için bir kural oluşturunей",
    "TSQuery": "TSQuery soruşturma",
    "SelectFlat": "Ödemeyi bağlamak istediğiniz bir veya daha fazla daire seçin",
    "RuleForMoreThanOneFlatImpossible": "Ödeme, seçilen daireler arasında eşit olarak dağıtılacaktır. Birden fazla daire için yinelenen ödeme kuralı oluşturulamaz.",
    "Resolve": "Ödeme bağlama",
    "ImportedBefore": "Daha önce {link} için ithal edildi",
    "SuccessFor": "{link} için ithal edilmiştir",
    "SuccessExpense": "Harcama olarak ithal edilmiştir",
    "ProblemMoreThanOneMatch": "Birden fazla uygun hesap",
    "ProblemZeroMatch": "Eşleşen hesap bulunamadı",
    "JustRemoveExisting": "{resolve} veya {remove} (bir sonraki içe aktarmada geri yüklenecektir)",
    "Remove": "Eski kayıtları silme",
    "Changed": "Kayıt değiştirildi, daha fazlasını değiştirmek için tekrar içe aktarın"
  }
}
</i18n>
<script setup>
import { ref } from 'vue';
import Check from 'Components/Check.vue';
import TextInput from 'Components/TextInput.vue';
import { useI18n } from 'vue-i18n';
import fetchApi from 'fetchApi';
import { money, transactionName, transactionDate } from 'Components/displays';
import StructuralLinks from 'Components/StructuralLinks';

const { t } = useI18n();
const props = defineProps({ result: Object, site: Object, showResolution: Boolean })
const selectedFlats = ref([]);
const addRule = ref(false);
const resolved = ref(false);
const ruleQuery = ref(props.result.sourceRecord.description.replace(/[^\p{Alpha}0-9]+/igu, ' <-> '));

function toggleSelection (flat) {
    const index = selectedFlats.value.indexOf(flat);
    if (index !== -1) {
        selectedFlats.value.splice(index, 1);
    } else {
        selectedFlats.value.push(flat);
    }
}

async function removeExisting () {
    await Promise.all(props.result.movements.map(activity => fetchApi('DELETE', '/api/account/movement/' + activity)));
    resolved.value = true;
}

async function resolve () {
    const reqs = [];
    if (addRule.value) {
        reqs.push(fetchApi('POST', '/api/account/bind-flat/' + selectedFlats.value[0].id, ruleQuery.value));
    }
    if (props.result.movements) {
        props.result.movements.map(activity => reqs.push(fetchApi('DELETE', '/api/account/movement/' + activity)));
    }

    reqs.push(fetchApi('POST', '/api/account/add-record',
        {
            accountActivityRecord: props.result.sourceRecord,
            flats: selectedFlats.value.map(x => x.id)
        }));

    await Promise.all(reqs);
    resolved.value = true;
}
</script>
<template>
  <div :class="$style.problemsRow">
    <div @click="$emit('showResolutionRequested')">
      {{ transactionDate(result.sourceRecord.timestamp) }}
    </div>
    <div>
      <template v-if="resolved">
        <span :class="fa.check"></span> {{ t('Changed') }}
      </template>
      <template v-else-if="result.status==='ImportedBefore'">
        <div v-for="(r,i) in result.related" :key="i">
          <template v-if="!i">
          <span :class="[fa.check,$style]"></span>
          <span :class="fa['clock-o']"></span>
          <i18n-t keypath="ImportedBefore">
            <template v-slot:link><StructuralLinks :reference="r"/></template>
          </i18n-t>
          </template>
          <StructuralLinks v-else :reference="r"/>
        </div>
      </template>
      <template v-else-if="result.status==='Success' && result.sourceRecord.amount<=0">
        <span :class="[fa.check,$style.green]"></span>
        {{ t('SuccessExpense') }}
      </template>
      <template v-else-if="result.status==='Success'">
        <span :class="[fa.check,$style.green]"></span>
        <i18n-t keypath="SuccessFor">
          <template v-slot:link><StructuralLinks :reference="result.related[0]"/></template>
        </i18n-t>
      </template>
      <template v-else>
        <span :class="[fa.remove,$style.red]"></span>
        {{ t(result.possibleAccounts?.length > 1 ? 'ProblemMoreThanOneMatch' : 'ProblemZeroMatch') }}
      </template>
    </div>
    <div @click="$emit('showResolutionRequested')">
      {{ transactionName(result.sourceRecord.description) }}
    </div>
    <div :class="$style.money" @click="$emit('showResolutionRequested')">
      {{ money(result.sourceRecord.amount) }}
    </div>
    <div>
      <button v-if="!resolved" :class="[buttons.xsmall, result.status==='Problem'?buttons.blue:buttons.gray]" @click="$emit('showResolutionRequested')">▼</button>
    </div>
    <div v-if="(result.status==='Problem'||result.movements?.length) && showResolution && !resolved"
         :class="$style.resolutionRow">
      <div v-for="building in site.buildings" :key="building.id" :class="$style.buildingDisplay">
        {{ building.name }}
        <div v-for="flat in building.flats" :key="flat.id"
             :class="selectedFlats.indexOf(flat)!==-1?$style.flatDisplaySelected:$style.flatDisplay"
             @click="toggleSelection(flat)">{{ flat.name }}
        </div>
      </div>
      <div v-if="selectedFlats.length===1" :class="$style.hflex">
        <Check v-model="addRule">{{ t('CreateRule') }}</Check>
        <TextInput v-if="addRule" v-model="ruleQuery">{{ t('TSQuery') }}</TextInput>
      </div>
      <div v-else-if="selectedFlats.length===0">
        {{ t('SelectFlat') }}
      </div>
      <div v-else>
        {{ t('RuleForMoreThanOneFlatImpossible') }} ({{
          (result.sourceRecord.amount / selectedFlats.length).toFixed(0)
        }})
      </div>
      <template v-if="result.movements?.length">
        <i18n-t keypath="JustRemoveExisting">
          <template v-slot:resolve>
            <button :disabled="selectedFlats.length===0" :class="[buttons.small, buttons.blue]" @click="resolve">
              {{ t('Resolve') }}
            </button>
          </template>
          <template v-slot:remove>
            <button :class="[buttons.small, buttons.red]" @click="removeExisting">{{ t('Remove') }}</button>
          </template>
        </i18n-t>
      </template>
      <button v-else :disabled="selectedFlats.length===0" :class="[buttons.small, buttons.blue]" @click="resolve">
        {{ t('Resolve') }}
      </button>
    </div>
  </div>
</template>
<style module="buttons" src="Components/Buttons.css"/>
<style module="fa" src="Components/fontawesome/index.css"/>
<style module>
.problemsRow {
  composes: stripedTableRow from 'Components/Table.css';
}

.resolutionRow {
  grid-column: span 5;
  padding: 1em;
}

.buildingDisplay {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
}

.money{
  text-align: right;
}

.green {
  color: var(--color-green);
}

.red {
  color: var(--color-red);
}

.flatDisplay {
  background: var(--color-background);
  color: var(--color-foreground);
  padding: .33em;
  border: 1px solid var(--color-gray);
  margin: .1em .25em;
  min-width: 1em;
  text-align: center;
  cursor: pointer;
}

.flatDisplaySelected {
  composes: flatDisplay;
  background: var(--color-selection-background);
  color: var(--color-selection-foreground);
  border-color: var(--color-accent);
}

.hflex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
