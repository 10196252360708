<i18n src="localisation.json"/>
<i18n>
{
  "ru": {
    "FinancesAndFundraisings": "Финансы и сборы",
    "Fundraising": "{name} до {dueDate} - собрано {collected} из {total}",
    "CompletedFundraising": "{name} - собрано {collected}",
    "DueDate": "Сбор до",
    "FundraisingName": "Название",
    "NewFundraising": "Новый сбор",
    "Finances": "Финансы",
    "Fundraisings": "Сборы",
    "ReportFor": "Отчёт за {formatted}",
    "Debt": "(долг)",
    "BigDebt": "(долг!)",
    "Prepay": "(предоплата)",
    "FloorNumber": "{count}",
    "TotalDebt": "Общая сумма долга",
    "Title": "Подробности о {site}",
    "FlatNo": "Кв. {name}",
    "FundraisingExemptable": "Регулярный сбор - исключения работают",
    "DebtCollection": "Работа с задолженностью",
    "AddDebtCollection": "Новое отслеживание задолженности с суммой более {threshold}",
    "DebtThreshold": "Минимальная сумма",
    "ExistingDebtCollection": "Сбор долгов начатый {startedOn} в сумме {initialDebt}"
  },
  "en": {
    "FinancesAndFundraisings": "Finances and fundraising campaigns",
    "Fundraising": "{name} till {dueDate} - {collected} of {total} collected",
    "CompletedFundraising": "{name} - {collected} collected",
    "DueDate": "Collect till",
    "FundraisingName": "Name",
    "NewFundraising": "New fundraising",
    "Finances": "Finances",
    "Fundraisings": "Fundraising campaigns",
    "ReportFor": "{formatted} report",
    "Debt": "(debt)",
    "BigDebt": "(debt!)",
    "Prepay": "(prepay)",
    "FloorNumber": "{count}",
    "TotalDebt": "Total debt",
    "Title": "{site} details",
    "FlatNo": "Apt. {name}",
    "FundraisingExemptable": "Periodic campaign, exemptions allowed",
    "DebtCollection": "Debt collection",
    "AddDebtCollection": "Create new debt collection tracking for debts more than {threshold}",
    "DebtThreshold": "Minimal amount",
    "ExistingDebtCollection": "Debt collection of {initialDebt} started on {startedOn}"
  },
  "tr": {
    "FinancesAndFundraisings": "Para ve para toplama",
    "Fundraising": "{name} {dueDate}'a kadar - toplanan {total}'ın {collected}'ı",
    "CompletedFundraising": "{name} - {total} toplandı",
    "DueDate": "Tarihten önce toplayın",
    "FundraisingName": "Açıklama",
    "NewFundraising": "Yeni para toplama",
    "Finances": "Para",
    "Fundraisings": "Para toplama",
    "ReportFor": "{formatted} ayı raporu",
    "Debt": "(borç)",
    "BigDebt": "(borç!)",
    "Prepay": "(ön ödeme)",
    "FloorNumber": "{count}",
    "TotalDebt": "Toplam borç",
    "Title": "{site} ayrıntıları",
    "FlatNo": "No. {name}",
    "FundraisingExemptable": "Periyodik toplama, izin verilen muafiyetler",
    "DebtCollection": "Borç yönetimi",
    "AddDebtCollection": "Tutarı {threshold} üzerinde olan borçların yeni takibi",
    "DebtThreshold": "Minimum tutar",
    "ExistingDebtCollection": "Borç tahsilatı {startedOn} tarihinde {initialDebt} tutarında başlatıldı"
  }
}
</i18n>

<script setup>
import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { money, transactionDate } from 'Components/displays';
import fetchApi from 'fetchApi';
import dayjs from 'dayjs';
import TextInput from 'Components/TextInput.vue';
import Fundraising from 'Models/Fundraising';
import Check from '../../../Components/Check.vue';
import { router } from 'main';
import { createDebtCollection } from '../../../Models/DebtCollection';

const { t } = useI18n();
const props = defineProps({ siteName: String });
document.title = t('Title', { site: props.siteName }) + t('MainTitle');
const site = ref(null);
const fundraisingName = ref('');
const fundraisingAmount = ref(0);
const fundraisingDueDate = ref('');
const fundraisingExemptable = ref(true);
const collectionThreshold = ref(1000);
const user = inject('user');

if (props.siteName) {
    const sites = await fetchApi('GET', '/api/structure/site/' + encodeURIComponent(props.siteName));
    site.value = sites.site;
} else {
    const sites = await fetchApi('GET', '/api/structure/site');
    site.value = sites.sites[0];
}
const summary = ref(await fetchApi('GET', '/api/account/summary/' + props.siteName));
const nMonths = ref(Math.max(Math.min(Number(localStorage.getItem('ViewMonths')), 1200), 3));
const visibleFundraisings = computed(() => {
    if (!summary.value) return [];
    const relDate = dayjs().add(-nMonths.value, 'month');
    return summary.value.site.fundraisings.filter(x => dayjs(x.dueDate).isAfter(relDate));
});
const reportMonths = computed(() => {
    let som = dayjs().add(1 - dayjs().date(), 'day');
    const items = [];
    for (let i = 0; i < nMonths.value; i++, som = som.add(-1, 'month')) {
        items.push({ date: som, formatted: som.format('MMMM YYYY') });
    }
    return items.reverse();
});
watch(nMonths, val => localStorage.setItem('ViewMonths', val));
const totalDebt = computed(() => {
    let value = 0;
    for (let i = 0; i < summary.value.site.buildings.length; i++) {
        value += buildingDebt(summary.value.site.buildings[i]);
    }
    return value;
});

function buildingDebt (b) {
    let value = 0;
    for (let k = 0; k < b.flats.length; k++) {
        const f = b.flats[k];
        if (f.disabled) continue;
        if (f.totalDues - f.paidDues - f.outstandingBalance > 0) {
            value += f.totalDues - f.paidDues - f.outstandingBalance;
        }
    }
    return value;
}

function flatsByFloor (flats) {
    const floors = [];
    for (let i = 0; i < flats.length; i++) {
        let floor = flats[i].floor;
        floor = floors.find(x => x.floor === floor) || floors[floors.push({ floor, flats: [] }) - 1];
        floor.flats.push(flats[i]);
    }
    floors.sort((a, b) => Number(a.floor) - Number(b.floor));
    return floors;
}

function flatClass (f) {
    if (f.disabled) {
        return 'Gray';
    }
    const debt = f.totalDues - f.paidDues - f.outstandingBalance;
    if (debt <= 100) return 'Green';
    if (debt <= 1000) return 'Yellow';
    return 'Red';
}

async function addFundraising () {
    await (new Fundraising({
        name: fundraisingName.value,
        dueDate: dayjs(fundraisingDueDate.value),
        amount: Number(fundraisingAmount.value),
        site: site.value.name
    })).save();
    summary.value = await fetchApi('GET', '/api/account/summary/' + props.siteName);
}

async function addDebtCollection () {
    const id = await createDebtCollection(site.value.name, collectionThreshold.value);
    router.push('/debts/' + encodeURIComponent(site.value.name) + '/' + id);
}
</script>

<template>
  <div>
    <Teleport to="#headerTeleportTarget">
      <span>{{ site.name }}</span>
    </Teleport>
    <div :class="$style.buildings">
      <div :class="$style.building">
        <h2>{{ t('FinancesAndFundraisings') }}</h2>
        <div :class="$style.hForm">
          <label>{{ t('ViewMonths') }} <input type="number" min="3" max="100" v-model="nMonths"
                                              :class="$style.inputNoBorderWidth"/></label>
        </div>
        <h4 :class="$style.subhead">{{ t('Finances') }}</h4>
        <div>{{ t('TotalDebt') }}: {{ money(totalDebt) }}</div>
        <div v-for="rm in reportMonths" :key="rm">
          <router-link :to="`/report/${encodeURIComponent(summary.site.name)}/${rm.date.year()}/${rm.date.month()+1}`">
            {{ t('ReportFor', rm) }}
          </router-link>
        </div>
        <h4 :class="$style.subhead">{{ t('Fundraisings') }}</h4>
        <div v-for="f in visibleFundraisings" :key="f.id">
          <span v-if="f.completed" :class="$style.completed"></span>
          {{
            t(f.completed ? 'CompletedFundraising' : 'Fundraising', {
              name: f.name,
              dueDate: transactionDate(f.dueDate),
              collected: money(f.collectedAmount),
              total: money(f.flats * f.amount)
            })
          }}
          <div v-if="!f.completed" :class="$style.progressOuter">
            <div :class="$style.progressInner"
                 :style="{width:Math.round(100*(f.collectedAmount)/ (f.flats*f.amount))+'%'}"></div>
          </div>
        </div>
        <h4 :class="$style.subhead">{{ t('NewFundraising') }}</h4>
        <form v-if="user.isManagerOf(summary.site.name)" @submit.stop.prevent="addFundraising" :class="$style.gForm">
          <TextInput v-model="fundraisingName">{{ t('FundraisingName') }}</TextInput>
          <TextInput v-model="fundraisingAmount" min="0" type="number">{{ t('Amount') }}</TextInput>
          <TextInput v-model="fundraisingDueDate" type="date">{{ t('DueDate') }}</TextInput>
          <button type="submit" :class="[buttons.blue, buttons.medium]"
                  :disabled="!fundraisingName||Number(fundraisingAmount)<1||!dayjs(fundraisingDueDate).isValid()">
            {{ t('Add') }}
          </button>
          <Check v-model="fundraisingExemptable">{{ t('FundraisingExemptable') }}</Check>
        </form>
        <h4 :class="$style.subhead">{{ t('DebtCollection') }}</h4>
        <div v-for="dc in summary.site.debtCollections" :key="dc.id">
          <router-link :to="`/debts/${site.name}/${dc.id}`">
            <i18n-t keypath="ExistingDebtCollection">
              <template v-slot:startedOn>{{ transactionDate(dc.startedOn) }}</template>
              <template v-slot:initialDebt>{{ money(dc.initialDebt) }}</template>
            </i18n-t>
          </router-link>
        </div>
        <form v-if="user.isManagerOf(summary.site.name)" @submit.stop.prevent="addDebtCollection" :class="$style.gForm">
          <i18n-t keypath="AddDebtCollection">
            <template v-slot:threshold>
              <TextInput v-model="collectionThreshold" min="0" type="number">{{ t('DebtThreshold') }}</TextInput>
            </template>
          </i18n-t>
          <button type="submit" :class="[buttons.blue, buttons.medium]">
            {{ t('Add') }}
          </button>
        </form>
      </div>
      <div v-for="b in summary.site.buildings" :key="b.id" :class="$style.building">
        <h2>
          <router-link :to="`/details/${encodeURIComponent(site.name)}/${encodeURIComponent(b.name)}`">{{
              b.name
            }}
          </router-link>
        </h2>
        <div :class="$style.buildingDebt">{{ t('TotalDebt') }}: {{ money(buildingDebt(b)) }}</div>
        <div :class="$style.floors">
          <div :class="$style.floor" v-for="floor in flatsByFloor(b.flats)" :key="floor.floor">
            <div :class="$style.floorName">{{ t('FloorNumber', {count: floor.floor}) }}</div>
            <div :class="$style.flats">
              <router-link v-for="f in floor.flats" :key="f.id" :class="$style['flat'+flatClass(f)]"
                           :to="`/details/${encodeURIComponent(site.name)}/${encodeURIComponent(b.name)}/${encodeURIComponent(f.name)}`">
                <span :class="$style.flatName">{{ t('FlatNo', {name: f.name}) }}</span>
                <span :class="$style.flatMoney">
                  {{ money(Math.abs(f.totalDues - f.paidDues - f.outstandingBalance)) }}
                </span>
                <span :class="$style.flatStatus">
                  <template v-if="f.totalDues - f.paidDues - f.outstandingBalance>1000">
                    {{ t('BigDebt') }}
                  </template>
                  <template v-else-if="f.totalDues - f.paidDues - f.outstandingBalance>0">
                    {{ t('Debt') }}
                  </template>
                  <template v-else-if="f.totalDues - f.paidDues - f.outstandingBalance<0">
                    {{ t('Prepay') }}
                  </template>
                  <template v-else>
                    &nbsp;
                  </template>
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style module>
.buildings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.building {
  padding: 1em;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray);
  border-radius: 5px;
  margin: 0 1em 1em 0;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
}

.building > h2 {
  text-align: center;
  margin: 0 0 .5em 0;
}

.buildingDebt {
  text-align: center;
}

.floors {
  display: flex;
  flex-direction: column-reverse;
}

.floor {
  display: flex;
  align-items: center;
}

.floorName {
  color: var(--color-gray);
  width: 1.4em;
  font-size: 110%;
}

.flats {
  display: flex;
}

.flat {
  display: flex;
  flex-direction: column;
  padding: .5em;
  color: var(--color-foreground);
  width: 6em;
  align-items: center;
}

a.flat, a.flat:visited, a.flat:hover, a.flat:active {
  color: var(--color-foreground);
  text-decoration: none;
}

.flat:hover {
  background: var(--color-background-alt);
  color: var(--color-foreground);
}

.flatName {
  font-size: 120%;
}

.flatMoney {

}

.flatStatus {
  font-size: 75%;
  margin-top: -.5em;
}

.flatGreen {
  composes: flat;
  background: var(--color-green-highlight);
}

.flatGreen:hover {
  background: var(--color-green-highlight-alt);
}

.flatGray {
  composes: flat;
  background: var(--color-gray-highlight);
  text-decoration: line-through !important;
}

.flatGray:hover {
  background: var(--color-gray-highlight-alt);
}

.flatYellow {
  composes: flat;
  background: var(--color-orange-highlight);
}

.flatYellow:hover {
  background: var(--color-orange-highlight-alt);
}

.flatRed {
  composes: flat;
  background: var(--color-red-highlight);
}

.flatRed:hover {
  background: var(--color-red-highlight-alt);
}

.flatHeader {
  composes: flat;
}

.flatHeader > * {
  font-weight: bold;
}

.hForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.gForm {
  display: grid;
  grid-template-columns: 1fr 4em 6em auto;
  grid-column-gap: .5em;
}

@media only screen and (max-width: 600px) {
  .gForm {
    grid-template-columns: auto auto;
  }
}

.hForm > * {
  margin: 0 .5em .25em 0;
}

.inputNoBorder {
  border: none;
  outline: none;
  display: block;
  width: 100%;
  margin: .25em 0;
}

.inputNoBorderWidth {
  composes: inputNoBorder;
  width: 4em;
  display: inline-block;
  margin: 0;
}

.completed {
  composes: check-circle from 'Components/fontawesome/index.css';
  color: var(--color-green);
}

.progressOuter {
  display: flex;
  background: var(--color-gray);
  padding: 1px;
  border: 1px solid var(--color-gray-darker);
  justify-items: start;
}

.progressInner {
  flex: 0 0 auto;
  height: 1em;
  background: var(--color-blue);
}

.subhead {
  margin: 1em 0 .25em 0;
}
</style>
<style module="fa" src="Components/fontawesome/index.css"/>
<style module="buttons" src="Components/Buttons.css"/>
