import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"
/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(script)
import block1 from "localisation.json?vue&type=custom&index=1&blockType=i18n&issuerPath=H%3A%5Cwork%5Cown%5CSiteManagerHelper%5CFrontend%5CPages%5CDashboard%5Cindex.vue&external"
if (typeof block1 === 'function') block1(script)


const __exports__ = script;

export default __exports__