<i18n src="localisation.json"/>
<i18n>
{
  "ru": {
    "InitialSuperuser": "Для начала работы нужно создать первого суперпользователя, который в дальнейшем будет иметь полный доступ к системе (созданию новых пользователей, определению структуры жилья, финансовым функциям)",
    "PasswordConfirmation": "Пароль ещё раз",
    "PasswordsDoNotMatch": "Пароли не совпадают",
    "PasswordTooShort": "Пароль должен быть не менее 6 символов",
    "BindToFlat": "Прикрепиться к квартире"
  }
}
</i18n>
<script setup>
import TextInput from 'Components/TextInput.vue';
import { ref, watch } from 'vue';
import Check from 'Components/Check.vue';
import Person from 'Models/Person';
import { ValidationError } from 'Models/parseError';
import { useI18n } from 'vue-i18n';
import auth from 'auth';
import initConfig from 'initConfig';
import { load } from 'Models/Site';
import { router } from 'main';

const { t } = useI18n();
const props = defineProps({ id: Number, initialSuperuser: Boolean });
const person = ref(new Person());
const validationErrors = ref({ name: null, phone: null, email: null });
const password1 = ref('');
const password2 = ref('');
const authUser = ref(await auth.profileLoaded);
const bindToFlat = ref(true);

const sites = ref([]);
const siteObj = ref(null);
const buildingObj = ref(null);
const selectedSite = ref(null);
const selectedBuilding = ref(null);
const selectedFlat = ref(null);
const tenantType = ref('owner');

if (initConfig.state.HasFunctionalFlat) {
    sites.value = await load();
    siteObj.value = sites.value[0];
    buildingObj.value = siteObj.value?.buildings[0];
    selectedSite.value = siteObj.value?.id;
    selectedBuilding.value = buildingObj.value?.id;
    selectedFlat.value = buildingObj.value?.flats[0].id;

    watch(selectedBuilding, (val) => {
        const b = siteObj.value.buildings.find(x => x.id === val);
        buildingObj.value = b;
        selectedFlat.value = b.flats[0].id;
    });
    watch(selectedSite, (val) => {
        const s = sites.value.find(x => x.id === val);
        siteObj.value = s;
        selectedBuilding.value = s.buildings[0].id;
    });
}

if (props.id) {
    person.value.id = props.id;
    await person.value.load();
} else {
    if (authUser.value && !authUser.value.superuser) {
        router.push('/');
    }
    person.value.superuser = props.initialSuperuser || false;
}

async function save () {
    try {
        person.value.password = password1.value;
        const profile = await person.value.save();
        auth.consumeProfile(profile);
        if (bindToFlat.value && initConfig.state.HasFunctionalFlat) {
            await person.value.bindToFlat(siteObj.value.name, buildingObj.value.name, buildingObj.value.flats.find(x => x.id === selectedFlat.value).name, tenantType.value);
        }
        window.location.reload();
    } catch (e) {
        if (e instanceof ValidationError) {
            validationErrors.value = e.propertyErrors;
        } else throw e;
    }
}

function validationErrorText (errArr) {
    if (!errArr) return '';
    return errArr.map(x => t('Error.' + x)).join(', ');
}
</script>
<template>
  <form @submit.stop.prevent="save">
    <p v-if="initialSuperuser">{{ t('InitialSuperuser') }}</p>
    <p>
      <TextInput v-model="person.name" :validation-error-text="validationErrorText(validationErrors.name)"
                 :is-valid="!validationErrors.name || !validationErrors.name.length">{{ t('Name') }}
      </TextInput>
    </p>
    <p>
      <TextInput type="email" v-model="person.email"
                 :validation-error-text="validationErrorText(validationErrors.email)"
                 :is-valid="!validationErrors.email || !validationErrors.email.length">{{ t('Email') }}
      </TextInput>
    </p>
    <p>
      <TextInput type="tel" pattern="\+?[0-9]+\s*\(?[0-9]+\)?\s*([0-9]+-)*[0-9]+" v-model="person.phone"
                 :validation-error-text="validationErrorText(validationErrors.phone)"
                 :is-valid="!validationErrors.phone || !validationErrors.phone.length">{{ t('Phone') }}
      </TextInput>
    </p>
    <p>
      <TextInput type="password" v-model="password1" :validation-error-text="t('PasswordTooShort')"
                 :is-valid="(props.id && !password1) || password1.length>=6">{{ t('Password') }}
      </TextInput>
    </p>
    <p>
      <TextInput type="password" v-model="password2" :validation-error-text="t('PasswordsDoNotMatch')"
                 :is-valid="(password1 || password2) && password1===password2">{{ t('PasswordConfirmation') }}
      </TextInput>
    </p>
    <p v-if="!initialSuperuser && (authUser && authUser.superuser)">
      <Check v-model="person.superuser">{{ t('Superuser') }}</Check>
    </p>
    <p v-if="initConfig.state.HasFunctionalFlat" :class="$style.hForm">
      <Check v-model="bindToFlat">{{ t('BindToFlat') }}</Check>
      <select v-model="selectedSite" v-if="sites.length>1">
        <option v-for="s in sites" :value="s.id" :key="s.id">{{ s.name }}</option>
      </select>
      <span v-else>{{ siteObj?.name }}</span>
      <select v-model="selectedBuilding" v-if="siteObj?.buildings.length>1">
        <option v-for="b in siteObj?.buildings" :value="b.id" :key="b.id">{{ b.name }}</option>
      </select>
      <select v-model="selectedFlat">
        <option v-for="f in buildingObj?.flats" :value="f.id" :key="f.id">{{ f.name }}</option>
      </select>
      {{ t('TenantTypeGenitive.Title') }}
      <select v-model="tenantType">
        <option value="owner">{{ t('TenantTypeGenitive.owner') }}</option>
        <option value="representative">{{ t('TenantTypeGenitive.representative') }}</option>
        <option value="renter">{{ t('TenantTypeGenitive.renter') }}</option>
        <option value="previousOwner">{{ t('TenantTypeGenitive.previousowner') }}</option>
        <option value="previousRenter">{{ t('TenantTypeGenitive.previousrenter') }}</option>
      </select>
    </p>
    <button type="submit" :class="[buttons.green, buttons.medium]">{{ t('Save') }}</button>
  </form>
</template>
<style module>
.hForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.hForm > * {
  margin-right: 1em;
}
</style>
<style module="buttons" src="Components/Buttons.css"/>
