export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "CreateRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать правило для повторяющихся платежей"])},
        "TSQuery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSQuery запрос"])},
        "SelectFlat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите одну или несколько квартир, к которой нужно привязать платёж"])},
        "RuleForMoreThanOneFlatImpossible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платёж будет распределён поровну между выбранными квартирами. Правило повторяющихся платежей не может быть создано для нескольких квартир."])},
        "Resolve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязать платёж"])},
        "ImportedBefore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Импортировано ранее для ", _interpolate(_named("link"))])},
        "SuccessFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Импортировано для ", _interpolate(_named("link"))])},
        "SuccessExpense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импортировано как расход"])},
        "ProblemMoreThanOneMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Более одного подходящего счёта"])},
        "ProblemZeroMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не найдено подходящего счёта"])},
        "JustRemoveExisting": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("resolve")), " или ", _interpolate(_named("remove")), " (восстановятся при следующем импорте)"])},
        "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить старые записи"])},
        "Changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запись изменена, повторите импорт чтобы изменить ещё"])}
      },
      "en": {
        "CreateRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create rule for recurring payments"])},
        "TSQuery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSQuery"])},
        "SelectFlat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select one or more flat to bind payment to"])},
        "RuleForMoreThanOneFlatImpossible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment will be split evenly between selected flats. Recurring payment rule cannot be created for multi-flat payments."])},
        "Resolve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bind payment"])},
        "ImportedBefore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Imported earlier for ", _interpolate(_named("link"))])},
        "SuccessFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Imported for ", _interpolate(_named("link"))])},
        "SuccessExpense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imported as spending"])},
        "ProblemMoreThanOneMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than one matching account"])},
        "ProblemZeroMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matching account found"])},
        "JustRemoveExisting": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("resolve")), " or ", _interpolate(_named("remove")), " (will appear again on next import)"])},
        "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove old records"])},
        "Changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record changed. Import once again for more changes."])}
      },
      "tr": {
        "CreateRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yinelenen ödemeler için bir kural oluşturunей"])},
        "TSQuery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSQuery soruşturma"])},
        "SelectFlat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeyi bağlamak istediğiniz bir veya daha fazla daire seçin"])},
        "RuleForMoreThanOneFlatImpossible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme, seçilen daireler arasında eşit olarak dağıtılacaktır. Birden fazla daire için yinelenen ödeme kuralı oluşturulamaz."])},
        "Resolve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme bağlama"])},
        "ImportedBefore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Daha önce ", _interpolate(_named("link")), " için ithal edildi"])},
        "SuccessFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " için ithal edilmiştir"])},
        "SuccessExpense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harcama olarak ithal edilmiştir"])},
        "ProblemMoreThanOneMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birden fazla uygun hesap"])},
        "ProblemZeroMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleşen hesap bulunamadı"])},
        "JustRemoveExisting": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("resolve")), " veya ", _interpolate(_named("remove")), " (bir sonraki içe aktarmada geri yüklenecektir)"])},
        "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eski kayıtları silme"])},
        "Changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt değiştirildi, daha fazlasını değiştirmek için tekrar içe aktarın"])}
      }
    }
  })
}
