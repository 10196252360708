class ValidationError extends Error {
    constructor (errors, offendingIds) {
        super();
        this.propertyErrors = errors;
        this.offendingIds = offendingIds;
    }
}
export { ValidationError };
export default function (response) {
    if (response?.error?.code === 'ValidationError') {
        throw new ValidationError(response.error.propertyErrors, response.error.offendingIds)
    } else throw new Error(JSON.stringify(response));
}
