<i18n src="localisation.json"/>
<i18n>
{
  "ru": {
    "RemoveTenant": "Удалить жителя",
    "EditTenant": "Редактировать жителя",
    "UserFound": "Пользователь с указаным адресом почты или телефоном найден. Можно использовать его, нажав соответствующую кнопку ниже.",
    "UsePerson": "Использовать существующего"
  },
  "en": {
    "RemoveTenant": "Remove tenant",
    "EditTenant": "Edit tenant",
    "UserFound": "User with the specified email/phone has been found. You can use it by pressing corresponding button below.",
    "UsePerson": "Use existing"
  },
  "tr": {
    "RemoveTenant": "Sakini silin",
    "EditTenant": "Sakini düzenleyin",
    "UserFound": "Belirtilen mail adresine veya telefon numarasına sahip bir kullanıcı bulundu. Aşağıdaki ilgili butona tıklayarak kullanabilirsiniz.",
    "UsePerson": "Mevcut kullanıcıyı kullanma"
  }
}
</i18n>
<script setup>
import TextInput from 'Components/TextInput.vue';
import { inject, ref } from 'vue';
import Person from 'Models/Person';
import { ValidationError } from 'Models/parseError';
import { useI18n } from 'vue-i18n';
import PersonModel from '../../Models/Person';

const { t } = useI18n();
const user = inject('user');
const props = defineProps({ tenant: Object, editMode: Boolean, site: String, building: String, flat: String });
const emit = defineEmits(['saved', 'editStarted', 'editCancelled']);
const isMobile = inject('isMobile');
const person = ref(new Person());
const validationErrors = ref({ name: null, phone: null, email: null });
const usePersonId = ref(null);
const mustUnbind = ref(!!props.tenant.id);
if (props.tenant.id) {
    person.value.id = props.tenant.id;
    await person.value.load();
}

async function save () {
    try {
        await person.value.save();
        props.tenant.id = person.value.id;
        props.tenant.name = person.value.name;
        props.tenant.phone = person.value.phone;
        props.tenant.email = person.value.email;
        if (mustUnbind.value) {
            await person.value.unbindFromFlat(props.site, props.building, props.flat);
        }
        await person.value.bindToFlat(props.site, props.building, props.flat, props.tenant.relation);
        emit('saved', person.value);
    } catch (e) {
        if (!mustUnbind.value) { props.tenant.id = null; }

        if (e instanceof ValidationError) {
            validationErrors.value = e.propertyErrors;
            if (e.offendingIds && e.offendingIds.length && !mustUnbind.value) {
                usePersonId.value = e.offendingIds[0];
            }
        } else throw e;
    }
}

async function saveWithExistingId () {
    person.value.id = usePersonId.value;
    try {
        await person.value.load();
        await save();
    } catch {
        person.value.id = null;
    }
}

function validationErrorText (errArr) {
    if (!errArr) return '';
    return errArr.map(x => t('Error.' + x)).join(', ');
}

function toEditMode () {
    emit('editStarted', true);
}

async function unbindTenant (tenant) {
    await (new PersonModel({ id: tenant.id })).unbindFromFlat(props.site, props.building, props.flat);
    emit('saved', person.value);
}
</script>
<template>
  <div v-if="!editMode" :class="$style.tenant">
    {{ t('TenantTypeNominative.' + tenant.relation) }}
    {{ tenant.name }}
    <a v-if="tenant.phone" target="_blank" :href="`tel:${tenant.phone}`"><span :class="fa.phone"></span>
      {{ tenant.phone }}</a>
    <a v-if="tenant.phone" target="_blank" :href="isMobile?`https://wa.me/${tenant.phone}`:`https://web.whatsapp.com/send?phone=${tenant.phone}`"><span
        :class="fa.whatsapp"></span></a>
    <a v-if="tenant.email" target="_blank" :href="`mailto:${tenant.email}`"><span :class="fa.envelope"></span>
      {{ tenant.email }}</a>

    <span>
    <button v-if="user.isManagerOf(site, building, flat)" :title="t('EditTenant')"
            :class="[buttons.gray, buttons.xsmall]" @click="toEditMode"><span :class="fa.pencil"></span></button>

    <button v-if="user.isManagerOf(site, building, flat)" :title="t('RemoveTenant')"
            :class="[buttons.orange, buttons.xsmall]" @click="unbindTenant(tenant)"><span :class="fa.remove"></span>
    </button>
    </span>
  </div>
  <form v-else @submit.stop.prevent="save">
    <p>
      <select v-model="tenant.relation">
        <option value="owner">{{ t('TenantTypeNominative.owner') }}</option>
        <option value="representative">{{ t('TenantTypeNominative.representative') }}</option>
        <option value="renter">{{ t('TenantTypeNominative.renter') }}</option>
        <option value="previousOwner">{{ t('TenantTypeNominative.previousowner') }}</option>
        <option value="previousRenter">{{ t('TenantTypeNominative.previousrenter') }}</option>
      </select>
    </p>
    <p>
      <TextInput v-model="person.name" :validation-error-text="validationErrorText(validationErrors.name)"
                 :is-valid="!validationErrors.name || !validationErrors.name.length">{{ t('Name') }}
      </TextInput>
    </p>
    <p>
      <TextInput type="email" v-model="person.email"
                 :validation-error-text="validationErrorText(validationErrors.email)"
                 :is-valid="!validationErrors.email || !validationErrors.email.length">{{ t('Email') }}
      </TextInput>
    </p>
    <p>
      <TextInput type="tel" pattern="\+?[0-9]+\s*\(?[0-9]+\)?\s*([0-9]+-)*[0-9]+" v-model="person.phone"
                 :validation-error-text="validationErrorText(validationErrors.phone)"
                 :is-valid="!validationErrors.phone || !validationErrors.phone.length">{{ t('Phone') }}
      </TextInput>
    </p>
    <p v-if="usePersonId">{{t('UserFound')}}</p>
    <button type="submit" :class="[buttons.green, buttons.small]">{{ t('Save') }}</button>
    <button v-if="usePersonId" :class="[buttons.blue, buttons.small]" @click="saveWithExistingId">{{ t('UsePerson') }}</button>
    <button :class="[buttons.red, buttons.small]" @click="emit('editCancelled')">{{ t('Cancel') }}</button>
  </form>
</template>
<style module>
.tenant {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: end;
}

.tenant > * {
  margin-left: .5em;
}
</style>
<style module="buttons" src="Components/Buttons.css"/>
<style module="fa" src="Components/fontawesome/index.css"/>
