export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "ApprovedRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Существующие привязки"])},
        "ClaimedRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязки ожидающие одобрения"])},
        "NewClaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить новую привязку"])},
        "NoRelationsAtAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш пользователь не привязан ни к одной квартире. Запросите привязку используя форму ниже, после одобрения администрацией вы сможете видеть подробные данные по своей квартире и суммарные отчёты."])},
        "ApproveTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утвердить"])},
        "IgnoreTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игнорировать"])},
        "RemoveTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
        "ClaimAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Такая привязка уже существует, повторное добавление невозможно."])},
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязка пользователя к квартире"])}
      },
      "en": {
        "ApprovedRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing bindings"])},
        "ClaimedRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bindings awaiting approval"])},
        "NewClaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim binding"])},
        "NoRelationsAtAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account is not bound to any flat. Claim binding using the form below, after management approval you will be able to access your flat details and site reports."])},
        "ApproveTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
        "IgnoreTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore"])},
        "RemoveTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
        "ClaimAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This binding already exists, it cannot be added again."])},
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account to flat bindings"])}
      },
      "tr": {
        "ApprovedRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut bağlamalar"])},
        "ClaimedRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlamalar onay bekliyor"])},
        "NewClaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir bağlama talep edin"])},
        "NoRelationsAtAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcınız herhangi bir daireye bağlı değil. Aşağıdaki formu kullanarak bir bağlama talep edin, yönetim tarafından onaylandıktan sonra daireniz için ayrıntılı verileri ve özet raporları görebileceksiniz."])},
        "ApproveTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])},
        "IgnoreTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yoksay"])},
        "RemoveTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
        "ClaimAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu bağ zaten mevcut, tekrar eklenemez."])},
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıyı bir daireye bağlama"])}
      }
    }
  })
}
