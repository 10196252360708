export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход в систему"])},
        "DoLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
        "InvalidLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильное сочетание телефона/почты и пароля"])},
        "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация нового пользователя"])},
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход в систему"])}
      },
      "en": {
        "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logon"])},
        "DoLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
        "InvalidLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email/phone or password"])},
        "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as new user"])},
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logon"])}
      },
      "tr": {
        "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş yapma"])},
        "DoLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş yap"])},
        "InvalidLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanlış telefon/e-posta ve şifre kombinasyonu"])},
        "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni kullanıcı kaydı"])},
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üye giriş"])}
      }
    }
  })
}
