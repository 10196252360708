<i18n src="localisation.json"/>
<i18n>
{
  "ru": {
    "AccountActivities": "Движение средств",
    "Remove": "Удалить эту запись",
    "RemoveNote": "Запись может появиться вновь при импорте банковской выписки",
    "ReBind": "Привязать к другой квартире",
    "AddIncome": "Добавить поступление",
    "AddAsCash": "Наличные",
    "DoRebind": "Привязать",
    "Fundraisings": "Сборы средств",
    "TotalBalance": "Итого нераспределённый остаток",
    "TotalDebt": "Полная сумма долга",
    "TotalDebtWithBalance": "Полная сумма долга с учётом остатка",
    "DefaultDescription": "Поступление наличными",
    "UseAsFine": "Списать часть суммы на счёт ситэ без сбора средств (штраф, безвоздмездная помощь)",
    "FineAmount": "Сумма",
    "Use": "Списать",
    "UseDescription": "Описание",
    "DefaultUseDescription": "Штраф за просроченный платёж",
    "RefreshFundraisings": "Перераспределить поступления по сборам",
    "Tenants": "Жители",
    "Title": "Подробности {building} {flat} {site}"
  },
  "en": {
    "AccountActivities": "Money movements",
    "Remove": "Remove record",
    "RemoveNote": "This money movement may appear again after importing bank account activity list.",
    "ReBind": "Bind to another flat",
    "AddIncome": "Add income",
    "AddAsCash": "Cash",
    "DoRebind": "Bind",
    "Fundraisings": "Fundraising campaigns",
    "TotalBalance": "Final balance",
    "TotalDebt": "Total debt",
    "TotalDebtWithBalance": "Total debt including leftover balance",
    "DefaultDescription": "Cash payment",
    "UseAsFine": "Deduct cash without fundraising campaign (fines or personal investment)",
    "FineAmount": "Amount",
    "Use": "Deduct",
    "UseDescription": "Description",
    "DefaultUseDescription": "Fine for late payment",
    "RefreshFundraisings": "Redistributes incomes to fundraising campaigns",
    "Tenants": "Tenants",
    "Title": "Details for {building} {flat} {site}"
  },
  "tr": {
    "AccountActivities": "Para hareketleri",
    "Remove": "Sil",
    "RemoveNote": "Bir banka ekstresi içe aktarılırken kayıt yeniden görünebilir",
    "ReBind": "Başka bir daireye bağla",
    "AddIncome": "Varış ekleyin",
    "AddAsCash": "Nakit",
    "DoRebind": "bağla",
    "Fundraisings": "Para toplayıcılar",
    "TotalBalance": "Toplam dağıtılmamış bakiye",
    "TotalDebt": "Toplam borç",
    "TotalDebtWithBalance": "Toplam ödenmiş bakiye dahil borç",
    "DefaultDescription": "Nakit ödeme",
    "UseAsFine": "Tutarın bir kısmının tahsil edilmeden site hesabına yansıtılması (para cezası, bahşiş)",
    "FineAmount": "Tutar",
    "Use": "Tahsil et",
    "UseDescription": "Açıklama",
    "DefaultUseDescription": "Geç ödeme cezası",
    "RefreshFundraisings": "Para toplayarak geliri yeniden tahsis edin",
    "Tenants": "Sakinleri",
    "Title": "Ayrıntılar {building} {flat} {site}"
  }
}
</i18n>
<script setup>
import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import DropDown from 'Components/DropDown.vue';
import Tenant from './Tenant';
import { load } from 'Models/Site';
import TextInput from 'Components/TextInput.vue';
import dayjs from 'dayjs';
import { transactionDate, transactionName, money } from 'Components/displays';
import fetchApi from 'fetchApi';

const { t } = useI18n();
const props = defineProps({ flat: String, site: String, building: String });
document.title = t('Title', { flat: props.flat, site: props.site, building: props.building }) + t('MainTitle');
const data = ref(null);
await reloadData();
const siteObj = ref(await load(props.site));
const buildingObj = ref(siteObj.value.buildings[0]);
const selectedBuilding = ref(buildingObj.value.id);
const selectedFlat = ref(buildingObj.value.flats[0].id);
const addAmount = ref(0);
const addDate = ref(dayjs().format('YYYY-MM-DD'))
const addAsCash = ref(true);
const addDescription = ref(t('DefaultDescription'));
const useDescription = ref(t('DefaultUseDescription'));
const user = inject('user');
const nMonths = ref(Math.max(Math.min(Number(localStorage.getItem('ViewMonths')), 1200), 3));
const visibleActivities = computed(() => {
    const relDate = dayjs().add(-nMonths.value, 'month');
    return data.value.accountActivities.filter(x => dayjs(x.timestamp).isAfter(relDate));
});
const editingTenant = ref(null);

const visibleFundraisings = computed(() => {
    const relDate = dayjs().add(-nMonths.value, 'month');
    return data.value.fundraisings.filter(x => dayjs(x.dueDate).isAfter(relDate));
});

const runningBalance = ref([]);
const balance = computed(() => data.value.accountActivities.reduce((acc, aa) => Number(acc) + aa.amount, 0));
const debt = computed(() => data.value.fundraisings.reduce((acc, f) => (f.completed || f.exempted ? 0 : f.amount) + Number(acc), 0));
const fineAmount = ref(0);

const actionsDropdown = ref(null);

watch(selectedBuilding, (val) => {
    const b = siteObj.value.buildings.find(x => x.id === val);
    buildingObj.value = b;
    selectedFlat.value = b.flats[0].id;
});
watch(nMonths, val => localStorage.setItem('ViewMonths', val));

async function reloadData () {
    data.value = await fetchApi('GET', `/api/account/details/${encodeURIComponent(props.site)}/${encodeURIComponent(props.building)}/${encodeURIComponent(props.flat)}`);
}

async function addNewTenant () {
    data.value.tenants.push(editingTenant.value = { id: null, name: '', email: '', phone: '', relation: 'owner' });
}

function calcRunningBalance (val) {
    runningBalance.value = [];
    let total = 0;
    for (let i = 0; i < val.accountActivities.length; i++) {
        total += Number(val.accountActivities[i].amount);
        runningBalance.value.push(total);
    }
}

calcRunningBalance(data.value);
watch(data, calcRunningBalance);

async function removeActivity (index, activity) {
    const result = await fetchApi('DELETE', '/api/account/movement/' + activity.reference);
    if (result.success) {
        data.value.accountActivities.splice(index, 1);
    }
    actionsDropdown?.value[index].toggleDropDown();
}

async function rebindActivity (index, activity) {
    const result = await fetchApi('POST', '/api/account/movement/' + activity.reference, selectedFlat.value);
    if (result.success) {
        data.value.accountActivities.splice(index, 1);
    }
    actionsDropdown?.value[index].toggleDropDown();
}

async function addIncome () {
    await fetchApi('POST', '/api/account/add-record', {
        accountActivityRecord: {
            timestamp: addDate.value,
            amount: addAmount.value,
            reference: addDate.value + '-' + Math.random(),
            description: addDescription.value
        },
        convertToCash: addAsCash.value,
        flats: [data.value.flat.id]
    });
    data.value = await fetchApi('GET', `/api/account/details/${encodeURIComponent(props.site)}/${encodeURIComponent(props.building)}/${encodeURIComponent(props.flat)}`);
}

async function useAsFine (index, aa) {
    await fetchApi('POST', '/api/account/add-record', {
        accountActivityRecord: {
            timestamp: dayjs(aa.timestamp).add(1, 's').toISOString(),
            amount: fineAmount.value,
            reference: aa.timestamp + '-' + Math.random(),
            description: useDescription.value
        },
        flats: [data.value.flat.id],
        nonFundraisingDebit: true
    });
    data.value = await fetchApi('GET', `/api/account/details/${encodeURIComponent(props.site)}/${encodeURIComponent(props.building)}/${encodeURIComponent(props.flat)}`);
    actionsDropdown?.value[index].toggleDropDown();
}

async function processFundraisings () {
    await fetchApi('GET', '/api/account/process-fundraising/' + data.value.flat.id);
    data.value = fetchApi('GET', `/api/account/details/${encodeURIComponent(props.site)}/${encodeURIComponent(props.building)}/${encodeURIComponent(props.flat)}`);
}

function cancelEditTenant () {
    if (!editingTenant.value?.id) {
        const ix = data.value.tenants.indexOf(editingTenant.value);
        if (ix !== -1) { data.value.tenants.splice(ix, 1); }
    }
    editingTenant.value = null;
}
</script>

<template>
  <div>
    <Teleport to="#headerTeleportTarget">
      <router-link :to="`/details/${ encodeURIComponent(site) }`">{{ site }}</router-link>
      <router-link :to="`/details/${ encodeURIComponent(site) }/${ encodeURIComponent(building) }`">{{
          building
        }}
      </router-link>
      <span>{{ flat }}</span>
    </Teleport>
    <div :class="[$style.hForm, bits.nonPrintable]">
      <label>{{ t('ViewMonths') }} <input type="number" min="3" max="100" v-model="nMonths"
                                          :class="$style.inputNoBorderWidth"/></label>
    </div>
    <div :class="$style.layout">
      <div :class="$style.layoutRight">
        <h3 :class="$style.fundraisingsHeader">{{ t('Fundraisings') }}
          <button type="button" :class="[buttons.orange, buttons.small]" @click="processFundraisings()">
            {{ t('RefreshFundraisings') }}
          </button>
        </h3>
        <div :class="$style.fundraisings">
          <div v-for="fr in visibleFundraisings" :key="fr.id" :class="fr.exempted?$style.exemptedFundraising:$style.fundraising">
            <div>{{ transactionDate(fr.dueDate) }}</div>
            <div><span :class="$style[fr.completed?'completed':'incomplete']"></span></div>
            <div>
              {{ fr.name }}
            </div>
            <div :class="$style.money">{{ money(fr.amount) }}</div>
            <div></div>
          </div>
          <div :class="$style.fundraisingFooter">
            <div>{{ t('TotalDebt') }}</div>
            <div :class="$style.money">{{ money(debt) }}</div>
            <div></div>
          </div>
          <div :class="$style.fundraisingFooter">
            <div>{{ t('TotalDebtWithBalance') }}</div>
            <div :class="$style.money">{{ money(Math.max(debt - balance, 0)) }}</div>
            <div></div>
          </div>
        </div>

        <h3 :class="$style.tenantsHeader">{{ t('Tenants') }}</h3>
        <div :class="$style.tenants">
          <Tenant v-for="(tenant,i) in data.tenants" :key="i" :site="site" :building="building" :flat="flat" :tenant="tenant" @edit-started="editingTenant=tenant" @edit-cancelled="cancelEditTenant"
                  @saved="editingTenant=null"
                  :edit-mode="editingTenant===tenant"/>
          <button v-if="editingTenant===null && user.isManagerOf(site, building, flat)" :class="[buttons.green, buttons.small]"
                  @click="addNewTenant">
            {{ t('AddTenant') }}
          </button>
        </div>
      </div>
      <div :class="$style.layoutLeft">
        <h3 :class="$style.accountActivitiesHeader">{{ t('AccountActivities') }}</h3>
        <div :class="$style.accountActivities">
          <div :class="$style.accountActivityHeader">
            <div>{{ t('Date') }}</div>
            <div>{{ t('Description') }}</div>
            <div>{{ t('Amount') }}</div>
            <div>{{ t('Balance') }}</div>
            <div></div>
            <div></div>
          </div>
          <div v-for="(aa, index) in visibleActivities" :key="aa.id" :class="$style.accountActivity">
            <div>{{ transactionDate(aa.timestamp) }}</div>
            <div><span :class="$style[aa.amount>0?'income':'expense']"/>
              {{ aa.amount > 0 ? transactionName(aa.description) : aa.description }}
            </div>
            <div :class="$style.money">{{ money(aa.amount) }}</div>
            <div :class="$style.money">{{
                money(runningBalance[runningBalance.length - visibleActivities.length + index])
              }}
            </div>
            <div>
              <DropDown v-if="aa.amount>0 && user?.isManagerOf(site, building)" ref="actionsDropdown">
                <template #default>
                  <button :class="[buttons.xsmall,buttons.blue]">&bullet;&bullet;&bullet;</button>
                </template>
                <template #drop>
                  <div :class="$style.dropDownContainer">
                    <p :class="$style.hForm">
                      <span :class="$style.hFormTitle">{{ t('ReBind') }}</span>
                      <select v-model="selectedBuilding">
                        <option v-for="b in siteObj.buildings" :value="b.id" :key="b.id">{{ b.name }}</option>
                      </select>
                      <select v-model="selectedFlat">
                        <option v-for="f in buildingObj.flats" :value="f.id" :key="f.id">{{ f.name }}</option>
                      </select>
                      <button :class="[buttons.orange, buttons.small]" @click="rebindActivity(index, aa)">
                        {{ t('DoRebind') }}
                      </button>
                    </p>
                    <hr/>
                    <p :class="$style.hForm">
                      <span :class="$style.hFormTitle">{{ t('UseAsFine') }}</span>
                      <TextInput type="number" min="0" max="aa.amount" v-model="fineAmount">{{ t('FineAmount') }}
                      </TextInput>
                      <TextInput v-model="useDescription">{{ t('UseDescription') }}</TextInput>
                      <button @click="useAsFine(index, aa)" :class="[buttons.blue, buttons.small]">{{
                          t('Use')
                        }}
                      </button>
                    </p>
                    <hr/>
                    <p>
                      {{ t('RemoveNote') }}
                      <br/>
                      <button :class="[buttons.red, buttons.small]" @click.stop.prevent="removeActivity(index, aa)">
                        {{ t('Remove') }}
                      </button>
                    </p>
                  </div>
                </template>
              </DropDown>
            </div>
            <div></div>
          </div>
          <div :class="$style.accountActivityFooter" v-if="user?.isManagerOf(site, building)">
            <span :class="$style.hFormTitle">{{ t('AddIncome') }}</span>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <form @submit.stop.prevent="addIncome" :class="$style.accountActivity"
                v-if="user?.isManagerOf(site, building)">
            <label><input type="date" :class="$style.inputNoBorder" v-model="addDate"/></label>
            <label><input :class="$style.inputNoBorder" v-model="addDescription"/></label>
            <label><input :class="$style.inputNoBorder" type="number" v-model="addAmount"/></label>
            <label><input type="checkbox" v-model="addAsCash" :class="$style.checkInGrid"/>{{ t('AddAsCash') }}</label>
            <label>
              <button type="submit" :class="[buttons.blue, buttons.xsmall]">{{ t('Add') }}</button>
            </label>
            <div></div>
          </form>
          <div :class="$style.accountActivityFooter">
            <div>{{ t('TotalBalance') }}</div>
            <div :class="$style.money">{{ money(balance) }}</div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style module="bits" src="Components/Bits.css"/>
<style module="buttons" src="Components/Buttons.css"/>
<style module="fa" src="Components/fontawesome/index.css"/>
<style module>
.layout {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 2em;
  align-content: start;
  align-items: start;
  grid-template-areas: "left right";
}

@media only screen and (max-width: 600px){
  .layout{
    display: flex;
    flex-direction: column;
  }
}

.layoutLeft {
  grid-area: left;
}

.layoutRight {
  grid-area: right;
}

.inputNoBorder {
  border: none;
  outline: none;
  display: block;
  width: 100%;
  margin: .25em 0;
}

.inputNoBorderWidth {
  composes: inputNoBorder;
  width: 4em;
  display: inline-block;
  margin: 0;
}

.checkInGrid {
  margin: 0 .25em;
}

.money {
  text-align: right;
}

@media (max-width: 900px) {
  .layout {
    grid-template-columns: 1fr;
    grid-row-gap: 2em;
    grid-template-areas: "aah" "aa" "fh" "f" "th" "t";
  }

  .fundraisings {
    grid-row: 2;
  }

  .accountActivities {
    grid-row: 1;
  }
}

.income {
  composes: sign-in from 'Components/fontawesome/index.css';
  color: var(--color-green-darker);
}

.expense {
  composes: sign-out from 'Components/fontawesome/index.css';
  color: var(--color-red-darker);
}

.completed {
  composes: check-circle from 'Components/fontawesome/index.css';
  color: var(--color-green-darker);
}

.incomplete {
  composes: remove from 'Components/fontawesome/index.css';
  color: var(--color-red-darker);
}

.accountActivitiesHeader {
  grid-area: aah;
}

.accountActivities {
  composes: stripedTable from 'Components/Table.css';
  grid-area: aa;
  grid-template-columns: max-content auto 7em 7em max-content auto;
}

.accountActivity, .fundraising {
  composes: stripedTableRow from 'Components/Table.css';
}

.exemptedFundraising {
  composes: fundraising;
  text-decoration: line-through;
}
.exemptedFundraising>*{
  text-decoration: line-through;
}
.accountActivityFooter, .accountActivityHeader, .fundraisingFooter {
  composes: stripedTableHeadRow from 'Components/Table.css';
}

.accountActivityFooter > *:first-child {
  grid-column: span 3;
}

.fundraisingsHeader {
  grid-area: fh;
}

.fundraisings {
  grid-area: f;
  composes: stripedTable from 'Components/Table.css';
  grid-template-columns: max-content 2em auto auto auto;
}

.fundraisingFooter > *:first-child {
  grid-column: span 3;
}

.hForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.hForm > * {
  margin: 0 .5em .25em 0;
}

.hFormTitle {
  width: 100%;
}

.inputWidth {
  width: 8em;
}

.inputWidthMore {
  width: 16em;
}

.dropDownContainer {
  max-width: 350px;
}

.tenantsHeader {
  grid-area: th;
}

.tenants {
  grid-area: t;
}

.tenant {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: end;
}

.tenant > * {
  margin-left: .5em;
}
</style>
