export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа с задолженностью"])},
        "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершить сбор"])}
      },
      "en": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debt collection"])},
        "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete collection"])}
      },
      "tr": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borç yönetimi"])},
        "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koleksiyonu tamamlayın"])}
      }
    }
  })
}
