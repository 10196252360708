import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import routes from './routes';
import { createRouter, createWebHistory } from 'vue-router';
import css from './main.css';
import initConfig from './initConfig';
import LayoutWithHeader from './LayoutWithHeader.vue';
import auth from './auth';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import 'dayjs/locale/tr';
import MobileDetect from 'mobile-detect';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
const supportedLocales = ['en', 'ru', 'tr'];
function matchLocale (l) {
    if (supportedLocales.indexOf(l) !== -1) return l;
    return null;
}

const locale = matchLocale(localStorage.getItem('language')) || matchLocale(navigator.language) || navigator.languages.find(x => matchLocale(x)) || supportedLocales[0];
const rootWrapper = document.createElement('div');
rootWrapper.className = css.rootWrapper;
document.body.appendChild(rootWrapper);
const router = createRouter({ history: createWebHistory(), routes });
router.beforeEach(async (to, from) => {
    if (initConfig.state.HasSuperuser && to.matched.some(route => route.meta.initialOnly)) {
        return '/';
    } else if (!to.matched.some(route => route.meta.allowNonLoggedIn)) {
        if (!initConfig.state.HasSuperuser) {
            return '/initial-setup/create-superuser';
        } else {
            if (!await auth.isAuthenticated()) {
                return '/login';
            } else if (!auth.profile.relations.length && !auth.profile.superuser && to.path !== '/claims') {
                return '/claims';
            }
        }
    }
});
const app = createApp(LayoutWithHeader);
dayjs.locale(locale);
app.provide('locale', locale);
app.provide('locales', supportedLocales);
app.provide('isMobile', !!(new MobileDetect(window.navigator.userAgent).phone()));
app.use(createI18n({
    legacy: false,
    locale,
    messages: { en: {}, ru: {} }
}));
app.use(router);
app.mount(rootWrapper);

export { app, router };
