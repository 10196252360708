<i18n src="localisation.json"/>
<i18n>
{
  "ru": {
    "Login": "Вход в систему",
    "DoLogin": "Войти",
    "InvalidLogin": "Неправильное сочетание телефона/почты и пароля",
    "Register": "Регистрация нового пользователя",
    "Title": "Вход в систему"
  },
  "en": {
    "Login": "Logon",
    "DoLogin": "Sign in",
    "InvalidLogin": "Incorrect email/phone or password",
    "Register": "Register as new user",
    "Title": "Logon"
  },
  "tr": {
    "Login": "Giriş yapma",
    "DoLogin": "Giriş yap",
    "InvalidLogin": "Yanlış telefon/e-posta ve şifre kombinasyonu",
    "Register": "Yeni kullanıcı kaydı",
    "Title": "Üye giriş"
  }
}
</i18n>
<template>
  <form @submit.stop.prevent="tryLogin">
    <Teleport to="#headerTeleportTarget"><span>{{ t('Login') }}</span></Teleport>
    <p>
      <TextInput v-model="identity">{{ t('EmailOrPhone') }}</TextInput>
    </p>
    <p>
      <TextInput type="password" v-model="password">{{ t('Password') }}</TextInput>
    </p>
    <p v-if="isError">{{ t('InvalidLogin') }}</p>

    <p :class="$style.buttons">
      <router-link to="/register">{{ t('Register') }}</router-link>
      <span></span>
      <button type="submit" :class="[buttons.green, buttons.medium]">{{ t('DoLogin') }}</button>
    </p>
  </form>
</template>
<style module>
.buttons{
  display: grid;
  grid-template-columns: max-content 1fr max-content;
}
</style>
<style module="buttons" src="Components/Buttons.css"/>
<script setup>
import TextInput from 'Components/TextInput.vue';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import auth from 'auth';
import { router } from 'main';

const { t } = useI18n();
document.title = t('Title') + t('MainTitle');

const identity = ref('');
const password = ref('');
const isError = ref(false);

if (await auth.isAuthenticated()) router.push('/');

async function tryLogin () {
    if (await auth.tryLogin(identity.value, password.value)) router.push('/');
    else isError.value = true;
}
</script>
