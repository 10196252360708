export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "InitialSuperuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для начала работы нужно создать первого суперпользователя, который в дальнейшем будет иметь полный доступ к системе (созданию новых пользователей, определению структуры жилья, финансовым функциям)"])},
        "PasswordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль ещё раз"])},
        "PasswordsDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли не совпадают"])},
        "PasswordTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен быть не менее 6 символов"])},
        "BindToFlat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикрепиться к квартире"])}
      }
    }
  })
}
