import dayjs from 'dayjs';
import fetchApi from '../fetchApi';
import parseError from './parseError';

export default class Fundraising {
    constructor (src) {
        Object.assign(this, { id: null, name: '', dueDate: dayjs(), amount: 0 }, src)
    }

    async save () {
        const result = await fetchApi('POST', this.id ? '/api/fundraising/' + this.id : '/api/fundraising', this);
        if (result.success) {
            this.id = result.id;
            return this;
        } else throw parseError(result);
    }
}
