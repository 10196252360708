export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "PendingClaims": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["-"]), _normalize([_interpolate(_named("count")), " пользователь ожидает подтверждения"]), _normalize([_interpolate(_named("count")), " пользователя ожидает подтверждения"]), _normalize([_interpolate(_named("count")), " пользователей ожидает подтверждения"])])},
        "ImportAA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импорт выписки"])},
        "Language": {
          "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
          "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
          "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Английский/English"])},
          "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Турецкий/Türkçe"])}
        }
      },
      "en": {
        "PendingClaims": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["-"]), _normalize([_interpolate(_named("count")), " user awaiting confirmation"]), _normalize([_interpolate(_named("count")), " users awaiting confirmation"])])},
        "ImportAA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import account activities"])},
        "Language": {
          "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
          "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian/Русский"])},
          "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
          "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish/Türkçe"])}
        }
      },
      "tr": {
        "PendingClaims": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["-"]), _normalize([_interpolate(_named("count")), " kullanıcı onay bekliyor"])])},
        "ImportAA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap hareketleri yükleme"])},
        "Language": {
          "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
          "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rusça/Русский"])},
          "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İngilizce/English"])},
          "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkçe"])}
        }
      }
    }
  })
}
