import CenteredView from 'Components/CenteredView'
import ImportAccountActivities from 'Pages/ImportAccountActivities';
import SiteDetails from 'Pages/Site/Details';
import SiteReport from 'Pages/Site/Report';
import FlatDetails from 'Pages/FlatDetails';
import Login from 'Pages/Login';
import Register from 'Pages/Login/Register'
import Claims from 'Pages/Claims';
import Dashboard from 'Pages/Dashboard';
import Debts from 'Pages/Debts';

export default [
    {
        path: '/',
        component: Dashboard,
        props: true
    },
    {
        path: '/import',
        component: ImportAccountActivities,
        props: true
    },
    {
        path: '/details/:siteName',
        component: SiteDetails,
        props: true
    },
    {
        path: '/debts/:siteName/:id',
        component: Debts,
        props: true
    },
    {
        path: '/details/:siteName/:buildingName',
        component: SiteDetails,
        props: true
    },
    {
        path: '/report/:site/:year/:month',
        component: SiteReport,
        props: true
    },
    {
        path: '/details/:site/:building/:flat',
        component: FlatDetails,
        props: true
    },
    {
        path: '/login',
        meta: {
            allowNonLoggedIn: true
        },
        component: CenteredView,
        props: {
            content: Login,
            contentProps: {
                initialSuperuser: true
            }
        }
    },
    {
        path: '/claims',
        component: CenteredView,
        props: {
            content: Claims,
            contentProps: {
            }
        }
    },
    {
        path: '/register',
        meta: {
            allowNonLoggedIn: true
        },
        component: CenteredView,
        props: {
            content: Register,
            contentProps: {
                initialSuperuser: false
            }
        }
    },
    {
        path: '/initial-setup/create-superuser',
        meta: {
            allowNonLoggedIn: true,
            initialOnly: true
        },
        component: CenteredView,
        props: {
            content: Register,
            contentProps: {
                initialSuperuser: true
            }
        }
    }
]
