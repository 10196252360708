export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "MainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - финансы ситэ"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес эл. почты"])},
        "EmailOrPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес почты или телефон"])},
        "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полное имя"])},
        "Superuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Суперпользователь"])},
        "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
        "ViewMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать данные за последние месяцы"])},
        "TenantTypeGenitive": {
          "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в качестве"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["владельца"])},
          "representative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["представителя"])},
          "renter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["арендатора"])},
          "previousowner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["предыдущего владельца"])},
          "previousrenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["предыдущего арендатора"])},
          "Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["управляющего"])}
        },
        "TenantTypeNominative": {
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец"])},
          "representative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Представитель"])},
          "previousowner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предыдущий владелец"])},
          "previousrenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предыдущий арендатор"])},
          "renter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арендатор"])}
        },
        "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
        "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
        "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
        "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
        "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
        "Error": {
          "ValueMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это поле нужно заполнить"])},
          "ValueNotUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Такое значение уже существует"])}
        },
        "AddTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить жителя"])}
      },
      "en": {
        "MainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - site finances"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
        "EmailOrPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address or phone number"])},
        "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
        "Superuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superuser"])},
        "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "ViewMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show data for last months"])},
        "TenantTypeGenitive": {
          "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["owner"])},
          "representative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["owner representative"])},
          "previousowner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["previous owner"])},
          "previousrenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["previous renter"])},
          "renter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["renter"])},
          "Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manager"])}
        },
        "TenantTypeNominative": {
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
          "representative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner representative"])},
          "previousowner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous owner"])},
          "previousrenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous renter"])},
          "renter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renter"])}
        },
        "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
        "Error": {
          "ValueMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is mandatory"])},
          "ValueNotUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value already used"])}
        },
        "AddTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tenant"])}
      },
      "tr": {
        "MainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - sitenin finans"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresi"])},
        "EmailOrPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresi ya da telefon numara"])},
        "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon numara"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
        "Superuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süper Kullanıcı"])},
        "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre"])},
        "ViewMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son aylara ait bilgileri göster"])},
        "TenantTypeGenitive": {
          "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["için"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sahibi"])},
          "representative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yetkili"])},
          "previousowner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eski sahibi"])},
          "previousrenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eski kiraci"])},
          "renter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kiraci"])},
          "Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yönetici"])}
        },
        "TenantTypeNominative": {
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sahip"])},
          "representative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sahibi yetkilisi"])},
          "previousowner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eski sahip"])},
          "previousrenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eski kiraci"])},
          "renter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiraci"])}
        },
        "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddet"])},
        "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarıh"])},
        "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miktar"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
        "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekle"])},
        "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bakiye"])},
        "Error": {
          "ValueMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan doldurulmalıdır"])},
          "ValueNotUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Böyle bir değer zaten mevcuttur"])}
        },
        "AddTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni sakini ekleyin"])}
      }
    }
  })
}
