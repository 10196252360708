import fetchApi from '../fetchApi';

export default class Site {
    constructor (src) {
        Object.assign(this, { id: 0, name: '', buildings: null }, src);
    }

    async load () {
        if (this.buildings !== null) return;
        const result = await fetchApi('GET', '/api/structure/site/' + this.id);
        if (result.success) {
            this.buildings = result.site.buildings;
        } else throw new Error('Error loading Site ' + JSON.stringify(result.error));
    }
}

export async function load (name) {
    if (name) {
        const s = new Site({ id: name });
        await s.load();
        return s;
    }
    const result = await fetchApi('GET', '/api/structure/site-structure');
    if (result.success) {
        return result.sites.map(x => new Site(x));
    } else throw new Error('Error loading Sites ' + JSON.stringify(result.error));
}
