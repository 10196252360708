<template>
  <div :class="[$style.wrapper, {[$style.in]:anim}]" :style="{left: bindPointLeft, top:bindPointTop}" ref="me">
    <slot></slot>
  </div>
</template>
<style module src="./DropDown.css"/>
<script>
import { ref, markRaw, computed } from 'vue';

export default {
    props: {
        container: Object
    },
    setup (props) {
        const parent = markRaw(props.container);
        const parentScreenCoordinates = computed(() => {
            let el = parent;
            let _x = 0;
            let _y = 0;
            let ofsParent = el;
            while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
                _x -= el.scrollLeft;
                _y -= el.scrollTop;
                if (el === ofsParent) {
                    _x += el.offsetLeft;
                    _y += el.offsetTop;
                    ofsParent = el.offsetParent;
                }
                el = el.parentElement;
            }
            const b = parent.getBoundingClientRect();
            return new DOMRect(_x, _y, b.width, b.height);
        });
        const me = ref(null);
        return {
            me,
            anim: ref(false),
            parent,
            parentScreenCoordinates,
            bindPointLeft: computed(() => {
                const myW = me.value ? me.value.clientWidth : 0;
                if (parentScreenCoordinates.value.right + myW > window.innerWidth) {
                    return Math.round(Math.max(0, parentScreenCoordinates.value.right - myW)) + 'px';
                } else {
                    return Math.round((parentScreenCoordinates.value.left + parentScreenCoordinates.value.right) / 2) + 'px';
                }
            }),
            bindPointTop: computed(() => {
                const myH = me.value ? me.value.clientHeight : 0;
                if (parentScreenCoordinates.value.bottom + myH > window.innerHeight) {
                    return Math.round(Math.max(0, (parentScreenCoordinates.value.bottom) - myH)) + 'px';
                } else {
                    return Math.round((parentScreenCoordinates.value.bottom)) + 'px';
                }
            })
        }
    },
    mounted () {
        this.$nextTick(() => {
            setTimeout(() => {
                this.anim = true;
            }, 10);
        });
    }
};
</script>
