<i18n src="localisation.json"/>
<i18n>
{
  "ru": {
    "Contacts": "Контакты",
    "Comments": "Комментарии",
    "NewCommentText": "Текст комментария",
    "AddNewComment": "Добавить комментарий"
  },
  "en": {
    "Contacts": "Contacts",
    "Comments": "Comments",
    "NewCommentText": "New comment text",
    "AddNewComment": "Add comment"
  },
  "tr": {
    "Contacts": "İletişim",
    "Comments": "Yorumlar",
    "NewCommentText": "Yorum metni",
    "AddNewComment": "Yorum ekle"
  }
}
</i18n>
<script setup>
import StructuralLinks from '../../Components/StructuralLinks.vue';
import { dateTime, money } from '../../Components/displays';
import { ref, inject } from 'vue';
import Tenant from '../FlatDetails/Tenant.vue';
import { useI18n } from 'vue-i18n';
import TextInput from '../../Components/TextInput.vue';
import fetchApi from '../../fetchApi';

const { t } = useI18n();
const user = inject('user');
const props = defineProps({ item: Object, debtCollectionId: String });
const expanded = ref(false);
const editingTenant = ref(null);
const newCommentText = ref('');

function cancelEditTenant () {
    if (!editingTenant.value?.id) {
        const ix = props.item.tenants.indexOf(editingTenant.value);
        if (ix !== -1) {
            props.item.tenants.splice(ix, 1);
        }
    }
    editingTenant.value = null;
}

async function addNewTenant () {
    props.item.tenants.push(editingTenant.value = { id: null, name: '', email: '', phone: '', relation: 'owner' });
}

async function addNewComment () {
    if (/$\s*^/.test(newCommentText.value)) {
        return;
    }
    const nc = await fetchApi('POST', '/api/debts/' + props.debtCollectionId + '/' + encodeURIComponent(props.item.flat.site) + '/' + encodeURIComponent(props.item.flat.building) + '/' + encodeURIComponent(props.item.flat.flat) + '/comment', newCommentText.value);
    props.item.comments.push(nc.comment);
}
</script>

<template>
  <div :class="$style.flat">
    <div :class="$style.header" @click="expanded = !expanded">
      <StructuralLinks :reference="item.flat"/>
      <div :class="$style.progressOuter">
        <div :class="$style.progressInner"
             :style="{width:Math.round(Math.min(100,100*(item.collected)/ (item.initialDebt.amount)))+'%'}"></div>
      </div>
      {{ money(item.initialDebt) }}
    </div>
    <div :class="$style.details" v-if="expanded">
      <div :class="$style.tenants">
        <h4>{{ t('Contacts') }}</h4>
        <Tenant v-for="tenant in item.tenants" :key="tenant.id" :tenant="tenant" :site="item.flat.site" :building="item.flat.building" :flat="item.flat.flat" @edit-started="editingTenant=tenant" @edit-cancelled="cancelEditTenant"
                @saved="editingTenant=null"
                :edit-mode="editingTenant===tenant"/>
        <button v-if="editingTenant===null && user.isManagerOf(site, building, flat)" :class="[buttons.green, buttons.small]"
                @click="addNewTenant">
          {{ t('AddTenant') }}
        </button>
      </div>
      <div :class="$style.comments">
        <h4>{{ t('Comments') }}</h4>
        <div v-for="(comment,i) in item.comments" :key="i">
          {{ dateTime(comment.date) }} {{ comment.author }}: {{ comment.text }}
        </div>
        <TextInput v-model="newCommentText">{{ t('NewCommentText') }}</TextInput>
        <button :class="[buttons.small, buttons.green]" @click="addNewComment">{{ t('AddNewComment') }}</button>
      </div>
    </div>
  </div>
</template>
<style module="bits" src="Components/Bits.css"/>
<style module="buttons" src="Components/Buttons.css"/>
<style module>
.flat {
  margin: .5em 0;
  border: 1px solid var(--color-gray);
  box-shadow: var(--shadow-default);
  padding: .5em;
  border-radius: 5px;
}

.header {
  display: flex;
  flex-direction: row;
}

.details {
  display: flex;
  flex-direction: row;
  height: max-content;
}

.tenants {

}

.comments {
  max-height: 400px;
  overflow: hidden auto;
  flex: 1 1 auto;
  margin: 0 0 0 1em;
}

.progressOuter {
  display: flex;
  background: var(--color-gray);
  padding: 1px;
  border: 1px solid var(--color-gray-darker);
  justify-items: start;
  flex: 1 1 auto;
  margin: 0 1em;
}

.progressInner {
  flex: 0 0 auto;
  height: 100%;
  background: var(--color-blue);
}
</style>
