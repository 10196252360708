export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "FinancesAndFundraisings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финансы и сборы"])},
        "Fundraising": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " до ", _interpolate(_named("dueDate")), " - собрано ", _interpolate(_named("collected")), " из ", _interpolate(_named("total"))])},
        "CompletedFundraising": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " - собрано ", _interpolate(_named("collected"))])},
        "DueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбор до"])},
        "FundraisingName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "NewFundraising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый сбор"])},
        "Finances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финансы"])},
        "Fundraisings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сборы"])},
        "ReportFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Отчёт за ", _interpolate(_named("formatted"))])},
        "Debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(долг)"])},
        "BigDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(долг!)"])},
        "Prepay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(предоплата)"])},
        "FloorNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count"))])},
        "TotalDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая сумма долга"])},
        "Title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Подробности о ", _interpolate(_named("site"))])},
        "FlatNo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Кв. ", _interpolate(_named("name"))])},
        "FundraisingExemptable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регулярный сбор - исключения работают"])},
        "DebtCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа с задолженностью"])},
        "AddDebtCollection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Новое отслеживание задолженности с суммой более ", _interpolate(_named("threshold"))])},
        "DebtThreshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальная сумма"])},
        "ExistingDebtCollection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Сбор долгов начатый ", _interpolate(_named("startedOn")), " в сумме ", _interpolate(_named("initialDebt"))])}
      },
      "en": {
        "FinancesAndFundraisings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finances and fundraising campaigns"])},
        "Fundraising": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " till ", _interpolate(_named("dueDate")), " - ", _interpolate(_named("collected")), " of ", _interpolate(_named("total")), " collected"])},
        "CompletedFundraising": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " - ", _interpolate(_named("collected")), " collected"])},
        "DueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect till"])},
        "FundraisingName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "NewFundraising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New fundraising"])},
        "Finances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finances"])},
        "Fundraisings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundraising campaigns"])},
        "ReportFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("formatted")), " report"])},
        "Debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(debt)"])},
        "BigDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(debt!)"])},
        "Prepay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(prepay)"])},
        "FloorNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count"))])},
        "TotalDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total debt"])},
        "Title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("site")), " details"])},
        "FlatNo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apt. ", _interpolate(_named("name"))])},
        "FundraisingExemptable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodic campaign, exemptions allowed"])},
        "DebtCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debt collection"])},
        "AddDebtCollection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create new debt collection tracking for debts more than ", _interpolate(_named("threshold"))])},
        "DebtThreshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimal amount"])},
        "ExistingDebtCollection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Debt collection of ", _interpolate(_named("initialDebt")), " started on ", _interpolate(_named("startedOn"))])}
      },
      "tr": {
        "FinancesAndFundraisings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para ve para toplama"])},
        "Fundraising": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " ", _interpolate(_named("dueDate")), "'a kadar - toplanan ", _interpolate(_named("total")), "'ın ", _interpolate(_named("collected")), "'ı"])},
        "CompletedFundraising": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " - ", _interpolate(_named("total")), " toplandı"])},
        "DueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarihten önce toplayın"])},
        "FundraisingName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
        "NewFundraising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni para toplama"])},
        "Finances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para"])},
        "Fundraisings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para toplama"])},
        "ReportFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("formatted")), " ayı raporu"])},
        "Debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(borç)"])},
        "BigDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(borç!)"])},
        "Prepay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ön ödeme)"])},
        "FloorNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count"))])},
        "TotalDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam borç"])},
        "Title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("site")), " ayrıntıları"])},
        "FlatNo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No. ", _interpolate(_named("name"))])},
        "FundraisingExemptable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periyodik toplama, izin verilen muafiyetler"])},
        "DebtCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borç yönetimi"])},
        "AddDebtCollection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tutarı ", _interpolate(_named("threshold")), " üzerinde olan borçların yeni takibi"])},
        "DebtThreshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum tutar"])},
        "ExistingDebtCollection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Borç tahsilatı ", _interpolate(_named("startedOn")), " tarihinde ", _interpolate(_named("initialDebt")), " tutarında başlatıldı"])}
      }
    }
  })
}
