import dayjs from 'dayjs';

export const currencySymbols = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    TRY: '₺',
    JPY: '¥',
    RUB: '₽'
};
export function transactionDate (d) {
    return dayjs(d).format('YYYY-MM-DD')
}
export function dateTime (d) {
    return dayjs(d).format('L LT')
}
export function transactionName (n) {
    return n.replace(/^[A-Z]+\d+-?/, '').toLocaleLowerCase().replace(/(^|\s|-|,|\.)(\p{L})/gu, (r, s, l) => s + l.toLocaleUpperCase()).trim();
}

export function money (v, currency) {
    if (v && v.amount) {
        currency = v.currency;
        v = v.amount;
    }
    v = Number(v);
    if (Number.isNaN(v)) v = 0;
    if (v === 0) return '0 ' + currencySymbols[(currency || 'TRY')] || (currency || 'TRY');
    let rv = '';
    if (v < 0) { rv = '- '; v = -v; }
    let int = Math.trunc(v).toString();
    let intRv = '';
    while (int.length > 0) {
        const pos = Math.max(0, int.length - 3);
        intRv = int.substring(pos, pos + 3) + ' ' + intRv;
        int = int.substring(0, pos);
    }
    return rv + intRv + ' ' + currencySymbols[(currency || 'TRY')] || (currency || 'TRY');
}
