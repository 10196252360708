<i18n>
{
  "ru": {
    "PendingClaims": "-|{count} пользователь ожидает подтверждения|{count} пользователя ожидает подтверждения|{count} пользователей ожидает подтверждения",
    "ImportAA": "Импорт выписки",
    "Language": {
      "Title": "Язык",
      "ru": "Русский",
      "en": "Английский/English",
      "tr": "Турецкий/Türkçe"
    }
  },
  "en": {
    "PendingClaims": "-|{count} user awaiting confirmation|{count} users awaiting confirmation",
    "ImportAA": "Import account activities",
    "Language": {
      "Title": "Language",
      "ru": "Russian/Русский",
      "en": "English",
      "tr": "Turkish/Türkçe"
    }
  },
  "tr": {
    "PendingClaims": "-|{count} kullanıcı onay bekliyor",
    "ImportAA": "Hesap hareketleri yükleme",
    "Language": {
      "Title": "Dil",
      "ru": "Rusça/Русский",
      "en": "İngilizce/English",
      "tr": "Türkçe"
    }
  }
}
</i18n>
<script setup>
import { ref, provide, onMounted, inject } from 'vue';
import auth from './auth';
import { useI18n } from 'vue-i18n';
import { load } from 'Models/Claim';
import DropDown from './Components/DropDown.vue';

const { t } = useI18n();
const claims = ref([]);
const user = ref(auth.profile);
const locale = inject('locale');
const locales = inject('locales');

async function reloadClaims () {
    const newClaims = await load();
    claims.value.splice(0, claims.value.length);
    newClaims.map(c => claims.value.push(c));
}

function signOut () {
    auth.clear();
    return true;
}

provide('claims', { claims, reloadClaims });
provide('user', user);
onMounted(() => {
    auth.onProfileChange = n => {
        user.value = n;
        reloadClaims();
    }

    auth.isAuthenticated().then(a => a && reloadClaims());
});

function setLocale (l) {
    localStorage.setItem('language', l);
    window.location.reload();
}

</script>

<template>
  <div :class="$style.wrapper">
    <div :class="$style.header">
      <div :class="$style.headerCrumbs" id="headerTeleportTarget">
        <router-link to="/"><span :class="fa.home"/></router-link>
      </div>
      <div :class="$style.extension">
      </div>
      <router-link v-if="user && user.isManager" to="/import" :class="[$style.headerItem,bits.nonPrintable, bits.notMobile]">
        {{ t('ImportAA') }}
      </router-link>
      <div v-if="claims && claims.length && user.isManager" :class="$style.headerItem">
        <router-link to="/claims">{{ t('PendingClaims', {count: claims.length}) }}</router-link>
      </div>
      <div :class="bits.nonPrintable">
        <DropDown>
          <template #default><span :class="$style.headerItem"><span :class="fa.language"></span> {{
              t('Language.Title')
            }} - {{ t('Language.' + locale) }}</span></template>
          <template #drop>
            <div v-for="l in locales" :key="l"><a href="#" @click.stop.prevent="setLocale(l)">{{
                t('Language.' + l)
              }}</a></div>
          </template>
        </DropDown>
      </div>
      <div v-if="user" :class="[$style.user,bits.nonPrintable]">
        <a href="/api/person/signout" @click="signOut">{{ user.name }}</a>
      </div>
    </div>
    <div :class="$style.content">
      <router-view v-slot="{ Component }">
        <Suspense>
          <div>
            <component :is="Component"/>
          </div>
        </Suspense>
      </router-view>
    </div>
    <div :class="$style.overlay" id="overlayTeleportTarget" style="z-index:1000001"></div>
  </div>
</template>
<style module="bits" src="./Components/Bits.css"/>
<style module>
@import './vars.css';

:root {
  --header-height: 3em;
}

.wrapper {
  composes: rootWrapper from './main.css';
  display: grid;
  grid-template-rows: auto 1fr;
}

.header {
  background: var(--color-blue-darker);
  color: var(--color-blue-inverted);
  line-height: var(--header-height);
  padding: 0 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.header a, .header a:visited {
  color: var(--color-blue-inverted);
  text-decoration: none;
}

.header a:active, .header a:hover {
  color: var(--color-blue-inverted-alt);
  text-decoration: none;
}

.headerItem {
  margin-left: 1em;
}

.headerCrumbs {
  font-size: 30px;
}

.headerCrumbs > * {
  color: var(--color-blue-inverted) !important;
}

.headerCrumbs > *:first-child {
  padding-left: 0;
}

.headerCrumbs > *:before {
  content: '»';
  padding: 0 .5em;
  display: inline-block;
}

.headerCrumbs > *:first-child:before {
  display: none;
}

a.logo:visited, a.logo:hover, a.logo {
  color: var(--color-blue-inverted);
  text-decoration: none;
}

.extension {
  flex: 1 1 auto;
  padding: 0 1em;
}

.link {
  justify-self: flex-end;
  flex: 0 1 auto;
  color: var(--color-blue-inverted);
  padding: 0 1em;
  display: block;
}

.link:visited, .link:hover {
  color: var(--color-blue-inverted);
}

.linkActive {
  composes: link;
  background: var(--color-blue-alt);
}

.linkActive:visited, .linkActive:hover {
  background: var(--color-blue-alt);
}

.user {
  composes: headerItem;
  justify-self: flex-end;
  flex: 0 1 auto;
  text-align: right;
  margin-left: 2em;
}

.content {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1em;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.overlay > * {
  pointer-events: auto;
}

@media print {
  .header {
    background: var(--color-background-alt);
    color: var(--color-foreground) !important;
    height: auto;
    line-height: 1.5em;
  }

  .content{
    padding: 0;
  }

  .header a, .header a:visited, .header a:active, .header a:hover, .headerCrumbs > *, .link {
    color: var(--color-foreground) !important;
    font-size: 20px;
  }
  .overlay{
    display: none !important;
  }

  .wrapper{
    display: flex;
    flex-direction: column;
  }
}
</style>
<style module="fa" src="Components/fontawesome/index.css"/>
