export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "FundraisingsFulfillment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сборы средств"])},
        "Expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расходы"])},
        "AddCashSpending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить расход наличными"])},
        "TotalIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего собрано за месяц"])},
        "TotalSpendings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего расходы за месяц"])},
        "Title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Отчёт за ", _interpolate(_named("period")), " для ", _interpolate(_named("site"))])},
        "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачено"])},
        "Unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не оплачено"])},
        "Exempted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Освобождён от оплаты"])}
      },
      "en": {
        "FundraisingsFulfillment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundraising campaigns fulfillment"])},
        "Expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expenses"])},
        "AddCashSpending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add cash expense"])},
        "TotalIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month total income"])},
        "TotalSpendings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month total expenses"])},
        "Title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("period")), " report for ", _interpolate(_named("site"))])},
        "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
        "Unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not paid"])},
        "Exempted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exempted"])}
      },
      "tr": {
        "FundraisingsFulfillment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aidatların ve para toplanması takip edilmesi"])},
        "Expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giderler"])},
        "AddCashSpending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakit harcama ekleyin"])},
        "TotalIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ay için toplanan toplam tutar"])},
        "TotalSpendings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ay için toplam giderler"])},
        "Title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("site")), " ", _interpolate(_named("period")), " raporu"])},
        "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödenmiş"])},
        "Unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödenmemiş"])},
        "Exempted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeden muaftır"])}
      }
    }
  })
}
