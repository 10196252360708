<template>
    <div :class="wrapperClasses">
        <label :for="elementId" :class="$style.materialLabel" v-if="slotProvided">
            <slot/>
        </label>
        <input v-bind="inputAttributes" v-bind:value="modelValue" v-on="inputListeners">
        <small :class="$style.materialNote" v-if="descriptionProvided">{{ description }}</small>
        <p v-if="!isValid" :class="$style.materialValidationError">{{ validationErrorText }}</p>
    </div>
</template>
<style module src="./TextInput.css"/>
<script>
import IdGen from './idGenerator';

export default {
    inheritAttrs: false,
    props: {
        description: {
            type: String,
            default: undefined
        },
        inputType: {
            type: String,
            default: 'text'
        },
        modelValue: [String, Number],
        isValid: {
            type: Boolean,
            default: true
        },
        validationErrorText: String,
        transparent: Boolean
    },
    data () {
        return {
            focused: false
        };
    },
    watch: {
        isValid (_new, _old) {
            if (_new !== _old) {
                this.$emit('validation', _new);
            }
        }
    },
    computed: {
        elementId () {
            return this.inputAttributes.id;
        },
        descriptionProvided () {
            return ((this.description !== undefined || true) && this.description !== '');
        },
        slotProvided () {
            return this.$slots.default;
        },
        inputAttributes () {
            const defaults = {
                id: IdGen(),
                autocomplete: 'off',
                type: this.inputType
            };
            const attrsOverride = {
                class: [
                    this.transparent ? this.$style.materialInputTransparent : this.$style.materialInput,
                    { [this.$style.materialInputInvalid]: !this.isValid }
                ]
            };
            return Object.assign({}, defaults, this.$attrs, attrsOverride);
        },
        inputListeners: function () {
            const vm = this;
            // `Object.assign` объединяет объекты вместе, чтобы получить новый объект
            return Object.assign(
                {},
                // Затем мы можем добавить собственные слушатели или
                // перезаписать поведение некоторых существующих.
                {
                    // Это обеспечит, что будет работать v-model на компоненте
                    input: function (event) {
                        vm.$emit('update:modelValue', event.target.value);
                    },
                    focus: function (event) {
                        return vm.toggleFocus(event);
                    },
                    blur: function (event) {
                        return vm.toggleFocus(event);
                    }
                }
            );
        },

        wrapperClasses () {
            return [
                this.$attrs.class,
                this.transparent ? this.$style.materialWrapperTransparent : this.$style.materialWrapper,
                this.hasContent() ? this.$style.materialWrapperNotEmpty : '',
                this.focused ? this.$style.materialWrapperActive : ''
            ];
        }
    },
    methods: {
        toggleFocus () {
            this.focused = !this.focused;
            this.$emit(this.focused ? 'focus' : 'blur', this);
        },
        hasContent () {
            return (
                this.modelValue !== undefined && this.modelValue !== null && this.modelValue !== ''
            );
        }
    }
};
</script>
