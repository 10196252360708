<i18n>
{
  "ru": {
    "Title": "Доступные объекты",
    "SiteManager": "Управляющий жилым комплексом {site}",
    "BuildingManager": "Управляющий зданием {building} жилого комплекса {site}",
    "FlatTenant": "{relation} квартиры {site} » {building} » {flat}"
  },
  "en": {
    "Title": "Available objects",
    "SiteManager": "{site} site manager",
    "BuildingManager": "{site} {building} manager",
    "FlatTenant": "{relation} of {site} » {building} » {flat}"
  },
  "tr": {
    "Title": "Kullanılabilir tesisler",
    "SiteManager": "{site} yönetici",
    "BuildingManager": "{site} {building} apartman yönetici",
    "FlatTenant": "{site} » {building} » {flat} {relation}"
  }
}
</i18n>
<i18n src="localisation.json"/>
<script setup>
import { inject } from 'vue';
import { router } from 'main';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
document.title = t('Title') + t('MainTitle');

const user = inject('user');
if (user.value.relations.length === 1 && !user.value.superuser) {
    if (user.value.relations[0].relation === 'Manager') {
        if (user.value.relations[0].building) {
            router.push('/details/' + encodeURIComponent(user.value.relations[0].site) + '/' + encodeURIComponent(user.value.relations[0].building));
        } else {
            router.push('/details/' + encodeURIComponent(user.value.relations[0].site));
        }
    } else { router.push('/details/' + encodeURIComponent(user.value.relations[0].site) + '/' + encodeURIComponent(user.value.relations[0].building) + '/' + encodeURIComponent(user.value.relations[0].flat)); }
}
</script>

<template>
<div>
  <teleport to="#headerTeleportTarget"><span>{{t('Title')}}</span></teleport>
  <div v-for="(r,i) in user.relations" :key="i">
    <template v-if="r.relation==='Manager'">
      <router-link :to="`/details/${encodeURIComponent(r.site)}/${encodeURIComponent(r.building)}`" v-if="r.building">{{t('BuildingManager',r)}}</router-link>
      <router-link :to="`/details/${encodeURIComponent(r.site)}`" v-else>{{t('SiteManager',r)}}</router-link>
    </template>
    <template v-else>
<i18n-t keypath="FlatTenant">
  <template v-slot:relation>{{t('TenantTypeNominative.'+r.relation)}}</template>
  <template v-slot:site><router-link :to="`/details/${encodeURIComponent(r.site)}`">{{r.site}}</router-link></template>
  <template v-slot:building><router-link :to="`/details/${encodeURIComponent(r.site)}/${encodeURIComponent(r.building)}`">{{r.building}}</router-link></template>
  <template v-slot:flat><router-link :to="`/details/${encodeURIComponent(r.site)}/${encodeURIComponent(r.building)}/${encodeURIComponent(r.flat)}`">{{r.flat}}</router-link></template>
</i18n-t>
    </template>
  </div>
</div>
</template>

<style module>

</style>
