<i18n>
{
  "ru": {
    "Title": "Импорт банковской выписки со счёта",
    "SelectFile": "Выберите файл",
    "UploadForSite": "Для какого объекта выполнятся импорт",
    "FileFormat": "Формат выписки",
    "Send": "Загрузить",
    "ImportedRows": "Результаты импорта",
    "Status": "Состояние"
  },
  "en": {
    "Title": "Import bank account activity records",
    "SelectFile": "Select file",
    "UploadForSite": "Target account/site",
    "FileFormat": "Activity records format",
    "Send": "Upload",
    "ImportedRows": "Import results",
    "Status": "Status"
  },
  "tr": {
    "Title": "Hesaptan banka ekstresini içe aktarma",
    "SelectFile": "Dosya seçin",
    "UploadForSite": "Hedeflenen site",
    "FileFormat": "Ekstre formatı",
    "Send": "Yükle",
    "ImportedRows": "Sonuçları içe aktar",
    "Status": "Durum"
  }
}
</i18n>
<i18n src="localisation.json"/>
<template>
  <div :class="$style.wrapper">
    <teleport to="#headerTeleportTarget"><span>{{ t('Title') }}</span></teleport>
    <form @submit.stop.prevent="uploadFile">
      <label>
        {{ t('SelectFile') }}
        <input type="file" @change="file=$event.target.files[0]"/>
      </label>
      <label>
        {{ t('UploadForSite') }}
        <select v-model="site">
          <option v-for="site in sites" :key="site.id" :value="site.id">{{ site.name }}</option>
        </select>
      </label>
      <label>
        {{ t('FileFormat') }}
        <select v-model="format">
          <option value="GarantiBBVAXls">Garanti BBVA XLS</option>
          <option value="GarantiBBVA">Garanti BBVA</option>
        </select>
      </label>
      <button type="submit" :disabled="!file">{{ t('Send') }}</button>
    </form>

    <div v-if="results">
      <h4>{{ t('ImportedRows') }}</h4>
      <div :class="$style.resultsTable" v-if="results.length">
        <div :class="$style.resultsTableHeader">
          <span>{{ t('Date') }}</span>
          <span>{{ t('Status') }}</span>
          <span>{{ t('Description') }}</span>
          <span>{{ t('Amount') }}</span>
          <span></span>
        </div>
        <ImportedRecord v-for="result in results" :key="result.sourceRecord.reference" :result="result"
                        :site="selectedSite" @showResolutionRequested="expandedItem=result" :show-resolution="expandedItem===result"/>
      </div>
    </div>
  </div>
</template>
<style module>
.wrapper {
}

.resultsTable {
  composes: stripedTable from 'Components/Table.css';
  grid-template-columns: auto auto auto auto 1fr;
}

.resultsTableHeader {
  composes: stripedTableHeadRow from 'Components/Table.css';
}
</style>
<script setup>
import { useI18n } from 'vue-i18n';
import { watch, ref, shallowRef } from 'vue';
import { load } from 'Models/Site';
import auth from 'auth';
import ImportedRecord from './ImportedRecord';

const { t } = useI18n();
document.title = t('Title') + t('MainTitle');

const sitesRaw = await load();
const sites = ref(sitesRaw);
const site = ref(sitesRaw[0].id);
const format = ref('GarantiBBVAXls');
const file = shallowRef(null);
const results = ref(null);
const selectedSite = ref(null);
const expandedItem = ref(null);

async function selectSite () {
    selectedSite.value = sites.value.find(s => s.id === site.value);
    await selectedSite.value.load();
}

watch(site, selectSite);
selectSite();

function readFileBytes (file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
}

async function uploadFile () {
    const response = await fetch('/api/account/import', {
        method: 'PUT',
        headers: {
            'X-Format': format.value,
            'X-Site': site.value,
            'Content-Type': 'text/plain',
            Authorization: 'Bearer ' + auth.token
        },
        body: await readFileBytes(file.value)
    });
    const result = await response.json();
    results.value = result.results;
}
</script>
