export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
        "Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии"])},
        "NewCommentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст комментария"])},
        "AddNewComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить комментарий"])}
      },
      "en": {
        "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
        "Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
        "NewCommentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New comment text"])},
        "AddNewComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add comment"])}
      },
      "tr": {
        "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim"])},
        "Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumlar"])},
        "NewCommentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum metni"])},
        "AddNewComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum ekle"])}
      }
    }
  })
}
