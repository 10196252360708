import fetchApi from '../fetchApi';
import Person from './Person';

export default class Claim {
    constructor (src) {
        Object.assign(this, { person: null, relation: null, site: null, building: null, flat: null }, src);
        if (this.person) this.person = new Person(this.person);
    }

    delete () {
        return fetchApi('DELETE', `/api/person/${this.person.id}/claim/${encodeURIComponent(this.site)}/${encodeURIComponent(this.building)}/${encodeURIComponent(this.flat)}`);
    }

    ignore () {
        return fetchApi('PATCH', `/api/person/${this.person.id}/claim/${encodeURIComponent(this.site)}/${encodeURIComponent(this.building)}/${encodeURIComponent(this.flat)}`, {});
    }
}

export async function load () {
    const result = await fetchApi('GET', '/api/person/claims')
    if (result.success) {
        return result.claims.map(x => new Claim(x));
    } else throw new Error('Error loading Claims ' + JSON.stringify(result.error));
}
